import React from 'react';
import {ListGroup, Spinner} from "react-bootstrap";
import bootbox from "bootbox";
import {ActionsContext} from "../_context/ActionsContext";
import {validationErrorMessages} from "../helpers";

class Attachment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            removeAttachmentInProgress: false
        };

        this.confirmRemoveAttachment = this.confirmRemoveAttachment.bind(this);
    }

    confirmRemoveAttachment() {
        const {attachment, removeAttachment} = this.props;

        bootbox.confirm('Are you sure you want to delete this file?', (result) => {
            if (!result) return;

            this.setState({removeAttachmentInProgress: true});

            removeAttachment(attachment)
                .then(({data: removedAttachment}) => {
                    bootbox.alert('Attachment has been removed successfully.');
                    this.context.detachAttachment(removedAttachment);
                })
                .catch(({response}) => {
                    if (response.status === 422) {
                        bootbox.alert(validationErrorMessages(response.data));
                    }
               })
               .finally(() => this.setState({ removeAttachmentInProgress: false }));

        });
    }

    render() {
        const {attachment} = this.props;

        return <ListGroup.Item>
            <i className="fa fa-close" onClick={this.confirmRemoveAttachment} style={{cursor: "pointer"}}/>
            &nbsp;&nbsp;{attachment.name}
            &nbsp;{this.state.removeAttachmentInProgress && <Spinner animation="border" size="sm" className="pull-right" as="span"/>}
        </ListGroup.Item>
    }
}

Attachment.contextType = ActionsContext;

export default Attachment;