import React from 'react';
import Loader from "./Loader";

class VideoDefault extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    /**
     * Render loader.
     */
    renderLoader() {
        return (
            <div className="wide-container">
                <div className="text-center extra-large-margin-top">
                    <Loader />
                </div>
            </div>
        );
    }

    /**
     * Render video.
     */
    renderVideo() {
        return null;
    }

    render() {
        return (this.state.isLoading) ? this.renderLoader() : this.renderVideo();
    }
}

export default VideoDefault;