import React from 'react';
import {Accordion, Card } from "react-bootstrap";
import moment from 'moment'
import RevisionReview from './RevisionReview'
import $ from 'jquery';

class RevisionRow extends React.Component {

    componentDidMount() {
        // Toggle active class when accordion header is clicked.
        $('.card-header').click(function(){
            $('.card-header').removeClass("active");
            $(this).addClass("active");
        });
    }

    render() {
        const {revision, setSelectedRevision, index} = this.props;
        const createdAt = moment(revision.created_at).format('MMMM Do YYYY, h:mm a');
        const isLatestRevision = (index === 0);

        return <Card className="cursor-pointer">
            <Accordion.Toggle as={Card.Header} eventKey={index}>
                <div onClick={() => setSelectedRevision(revision)}>
                    <div>
                        <strong>{isLatestRevision ? `Latest Video - ${revision.id}` : `History Revision - ${revision.id}`}</strong>
                    </div>
                    <div>
                        <em>{`Created at ${createdAt}`}</em>
                    </div>
                </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index}>
                <Card.Body>
                    {
                        revision.reviews.length > 0 ?
                            revision.reviews.map(review => <RevisionReview review={review} key={review.id}/>) :
                            "No comment available for this video."
                    }
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    }
}

export default RevisionRow;