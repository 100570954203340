import React from 'react';

class DownloadLinks extends React.Component {
    render() {
        const {cdnMedia} = this.props;

        return <div>
            <div id="download-videos" className="inline-block-style small-margin-bottom" title='If video is ready, click download link to save video to your computer.'>
                <div id="download-group" className="bar-button">
                    <i className="bar-button__icon fas fa-photo-video"></i>
                    <span className="bar-button__text">Download</span>
                    <div className="clearfix"></div>
                </div>
                <div id="download-items">
                    {
                        Object.keys(cdnMedia).map(function(mediaType) {
                            const mediaObject = cdnMedia[mediaType];

                            return Object.keys(mediaObject)
                            // 1. If the download link is not yet available, we are not gonna show it.
                                .filter(function (mediaQuality) {
                                    return mediaObject[mediaQuality] !== null;
                                })
                                .map(function(mediaQuality) {
                                    const mediaUrl = process.env.REACT_APP_VMS_URL + 'download-file?url=' + mediaObject[mediaQuality];

                                    return (
                                        <div className="download-item" key={ mediaQuality }>
                                            { mediaQuality.toLowerCase() === 'source' ?
                                                <a className="video-ready download-link" href={ mediaUrl }> Source - Highest Quality</a>
                                                :
                                                <a className="video-ready download-link" href={ mediaUrl }> { mediaType.toUpperCase() } - { mediaQuality.toUpperCase() }</a>
                                            }
                                        </div>
                                    );
                                });
                        })
                    }
                </div>
            </div>
            <div className="clearfix"/>
        </div>
    }
}

export default DownloadLinks;