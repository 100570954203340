import React from 'react';
import FileNotFoundImage from '../_assets/images/404.png'

function NotFound() {
    return (
        <div className="container">
            <div className="error-page">
                <div className="row">
                    <div className="col">
                        <h1>Oops!<br />Something went wrong.</h1>
                        <h2>Sorry we can't seem to find the<br />page you're looking for.</h2>
                        <h3 className="large-margin-top large-margin-bottom">Error 404</h3>
                    </div>
                    <div className="col">
                        <img src={FileNotFoundImage} alt="404"/>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default NotFound;