import React from 'react';
import {Button, ButtonToolbar, Form} from "react-bootstrap";
import bootbox from "bootbox";
import axios from "../_config/axios";
import * as VideoType from '../_constants/videoType';

class YoutubeUploadForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            title: '',
            description: '',
            youtubePlaylistId: '',
            disableUploading: false,
            submitText: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.confirmUploadingToYoutube = this.confirmUploadingToYoutube.bind(this);
        this.uploadToYoutube = this.uploadToYoutube.bind(this);
    }

    componentDidMount() {
        this.setState({
            title: this.props.video.title,
            submitText: this.props.youtubeChannel.is_visdom ? 'Upload to Visual Domain' : 'Upload to Youtube'
        });
    }

    handleInputChange(e) {
        const { name, value } = e.target;

        this.setState({[name]: value});
    }

    confirmUploadingToYoutube() {
        const { title, description, youtubePlaylistId } = this.state;
        const { token, youtubeChannel } = this.props;

        this.setState({
            submitText: 'Uploading...',
            disableUploading: true
        });

        bootbox.confirm(`A video can only be uploaded once to Youtube.
                Once clicking upload, <strong style="color: red;"> PLEASE DO NOT REFRESH</strong> and <strong style="color: red;">STAY ON THIS FORM</strong> until the upload process is completed.  
                Are you sure you want to upload this video to Youtube?
            `, (result) => {
            if (!result) {
                this.setState({
                    submitText: youtubeChannel.is_visdom ? 'Upload to Visual Domain' : 'Upload to Youtube',
                    disableUploading: false
                });
                return;
            }

            this.uploadToYoutube({ title, description, token, youtube_playlist_id: youtubePlaylistId });
        });
    }

    uploadToYoutube(input) {
        const { video, updateVideoActions, youtubeChannel } = this.props;
        const {id: videoId, is_internal: isInternal} = video;

        let route = youtubeChannel.is_visdom ?
            `${process.env.REACT_APP_API_URL}youtube/visdom/${videoId}`
            :
            `${process.env.REACT_APP_API_URL}youtube/${videoId}`;


        return axios.post(route, {...input})
            .then((response) => {
                this.setState({ submitText: 'Uploaded' });
                bootbox.alert(`<h4>Congratulations! Your video has been uploaded to YouTube!</h4>
                    <p>Sometimes videos can take up to 1 hour to process on YouTube but when it's complete it will be visible on this link:</p>
                    <p class="large-margin-bottom"><a href="${response.data.youtube_url}" target="_blank">${response.data.youtube_url}</a></p>
                    <div>VIDEO TIP</div>
                    <p>To consolidate all your views in the one place Visual Domain recommend you share the YouTube link everywhere. Having multiple links can dilute your views and the videos SEO performance.</p>
                    <p>Can we help you with more sharing ideas? We’re here to help so please don’t hesitate to contact us for more information on getting your video seen.</p>`, () => {
                        if (isInternal) {
                            window.location.reload();
                        } else {
                            updateVideoActions();
                        }
                    }
                );
            })
            .catch((error) => {
                this.setState({
                    submitText: 'Upload to Youtube',
                    disableUploading: false
                });

                // This part will have to be updated ONCE the request change PR is merged.
                if (error.response && error.response.status === 422) {
                    const errorMessages = Object.values(error.response.data).flat().map(errorMessage => `<li>${errorMessage}</li>`).join('');
                    bootbox.alert(`<ul style="color: red">${errorMessages}</ul>`);
                }

                if (error.response && error.response.status === 400) {
                    bootbox.alert(error.response.data.message);
                }
            });
    }

    render() {
        const {
            youtubeChannel,
            video
        } = this.props;
        const videoType = video.video_type;

        return <div>
            {
                !youtubeChannel.is_visdom &&
                    <React.Fragment>
                        <h4>Let's get sharing!</h4>
                        <p>YouTube Upload. The upload process may take a while.<br/><strong style={{color: 'red'}}>PLEASE DO NOT REFRESH</strong> and <strong style={{color: 'red'}}>STAY ON THIS FORM</strong> until the upload process is completed.</p>
                    </React.Fragment>
            }

            <Form>
                <Form.Group controlId="title">
                    <Form.Control text="text" placeholder="Video title" name="title" value={this.state.title} onChange={this.handleInputChange}/>
                </Form.Group>
                <Form.Group controlId="description">
                    <Form.Control as="textarea" placeholder="Video description" name="description" value={this.state.description} onChange={this.handleInputChange}/>
                </Form.Group>
                {
                    this.props.youtubeChannel.playlists &&
                        <Form.Group controlId="youtubePlaylistId">
                            <Form.Control as="select" name="youtubePlaylistId" onChange={this.handleInputChange}>
                                <option value={this.state.youtubePlaylistId}>-- Select a playlist --</option>
                                {this.props.youtubeChannel.playlists.map(playlist => <option value={playlist.id} key={playlist.id}>{playlist.title}</option>)}
                            </Form.Control>
                        </Form.Group>
                }
                {
                    youtubeChannel.is_visdom && videoType !== VideoType.INTERNAL_VIDEO &&
                        <p>
                            Use this form to publish your video to <strong>Visual Domain’s</strong> Youtube channel. Simply fill in the fields below and click upload.
                            <strong style={{color: 'red'}}> PLEASE DO NOT REFRESH</strong> and <strong style={{color: 'red'}}>STAY ON THIS FORM</strong> until the upload process is completed.
                        </p>
                }
                <ButtonToolbar>
                    <Button variant="success" className="small-margin-right small-margin-bottom" onClick={this.confirmUploadingToYoutube} disabled={this.state.disableUploading}>{this.state.submitText}</Button>
                    <Button variant="danger" className="small-margin-right small-margin-bottom" onClick={() => this.props.setActiveComponent('downloadAndShare')} disabled={this.state.disableUploading}>Back to share</Button>
                </ButtonToolbar>
            </Form>
        </div>
    }
}

export default YoutubeUploadForm;