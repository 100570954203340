import React from "react";
import CaptionMenu from "./CaptionMenu";
import { Button, Form, Card, Spinner } from "react-bootstrap";

class EditCaption extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayCaptionEditor: false
    };
  }

  downloadSrt = url => {
    window.open(url, "_blank");
  };

  showCaptionEditor = () => {
    this.setState({ displayCaptionEditor: true });
  };

  hideCaptionEditor = () => {
    this.setState({ displayCaptionEditor: false });
  };

  // This will be called when there is no captions available to edit.
  renderNoCaption() {
    return (
      <>
        <div className="caption--body medium-margin-bottom">
          <Form.Group controlId="formGridCaptionLanguages">
            <CaptionMenu
              selectedMenu={this.props.selectedMenu}
              selectMenu={this.props.selectMenu}
            />
            <Form.Label className="caption--headings red">
              You don't have caption available to edit. Please order or upload
              caption to use this feature.
            </Form.Label>
          </Form.Group>
        </div>
      </>
    );
  }

  render() {
    let srtContent = "";
    let srtUrl = "";

    const captionDetails = this.props.getCaptionContent();

    if (captionDetails) {
      srtContent = captionDetails.srt_content;
      srtUrl = captionDetails.srt_url;
    }

    // No captions available to edit.
    if (this.props.captionLanguages.length <= 0) {
      return this.renderNoCaption();
    }

    if (!this.props.editComplete && !this.props.editFailed) {
      return (
        <>
          <div className="caption--body medium-margin-bottom">
            <Form.Group controlId="formGridCaptionLanguages">
              <CaptionMenu
                selectedMenu={this.props.selectedMenu}
                selectMenu={this.props.selectMenu}
              />
              {!this.state.displayCaptionEditor ? (
                <>
                  <Form.Label className="caption--headings">
                    Select a language
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue={this.props.languageId}
                    ref="uploadCaptionSelect"
                    onChange={() =>
                      this.props.handleSelectLanguage(
                        this.refs["uploadCaptionSelect"].value
                      )
                    }
                  >
                    {this.props.captionLanguages.map((language, index) => (
                      <option key={language.id} value={language.id}>
                        {language.label}
                      </option>
                    ))}
                  </Form.Control>
                </>
              ) : (
                <>
                  <Form.Label className="caption--headings">
                    Caption edit below
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    className="caption--options-notes--textarea"
                    rows="10"
                    ref="captionContent"
                    defaultValue={srtContent}
                  />
                </>
              )}
            </Form.Group>
          </div>
          <div className="caption--footer">
            <div className="caption--footer--submit">
              {!this.state.displayCaptionEditor ? (
                <>
                  <Button
                    className="caption--footer--submit-btn small-margin-right"
                    variant="primary"
                    onClick={() => this.showCaptionEditor()}
                  >
                    Edit
                  </Button>
                  <Button
                    className="caption--footer--submit-btn"
                    variant="primary"
                    onClick={() => this.downloadSrt(srtUrl)}
                  >
                    Download
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="caption--footer--submit-btn small-margin-right"
                    variant="primary"
                    onClick={() => this.hideCaptionEditor()}
                  >
                    Back
                  </Button>
                  <Button
                    className="caption--footer--submit-btn"
                    disabled={this.props.processingEdit}
                    onClick={e => {
                      this.props.editCaption(e, this.refs.captionContent.value);
                    }}
                  >
                    {!this.props.processingEdit ? (
                      "Save"
                    ) : (
                      <>
                        <span className="small-margin-right">Processing </span>
                        <Spinner as="span" animation="border" size="sm" />
                      </>
                    )}
                  </Button>
                </>
              )}
            </div>
            <div className="clearfix"></div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="caption--body medium-margin-bottom">
            <Form.Label className="caption--headings medium-margin-bottom">
              {this.props.editComplete
                ? "Caption Successfully Updated!"
                : "Failed to Edit Caption"}
            </Form.Label>
            <Card.Subtitle className="caption--sub-heading">
              {this.props.editComplete
                ? "We have successfully updated your caption. Please refresh this page to see your caption."
                : "There seems to be an error when we try to update the file, please close this popup and try again."}
            </Card.Subtitle>
          </div>
        </>
      );
    }
  }
}

export default EditCaption;
