import React from "react";
import { ARCHIVED } from "../_constants/orderJobStatus";
import GoBack from "./GoBack";
import BasicDownloadAndShare from "./BasicDownloadAndShare";
import UploadToSocialMedia from "./UploadToSocialMedia";
import ShareLink from "./ShareLink";
import { isEmpty } from "lodash";

class DownloadAndShare extends React.Component {
  render() {
    const {
      canDownloadVideo,
      videoUrlWithoutToken,
      cdnMedia,
      token,
      updateVideoActions,
      orderJobStatus,
      setActiveComponent,
      facebookPages,
      facebookUrl,
      youtubeAuthorisationUrl,
      youtubeUrl,
      youtubeChannel,
      youtubeStats,
      video
    } = this.props;
    const { title, id: videoId } = video;

    let socialMediaSection;

    if (isEmpty(video.branding)) {
      socialMediaSection = <UploadToSocialMedia
        facebookPages={facebookPages}
        facebookUrl={facebookUrl}
        setActiveComponent={setActiveComponent}
        youtubeAuthorisationUrl={youtubeAuthorisationUrl}
        youtubeUrl={youtubeUrl}
        youtubeChannel={youtubeChannel}
        videoUrlWithoutToken={videoUrlWithoutToken}
        youtubeStats={youtubeStats}
      />
    }

    return (
      <div>
        <BasicDownloadAndShare
          canDownloadVideo={canDownloadVideo}
          videoUrlWithoutToken={videoUrlWithoutToken}
          title={title}
          cdnMedia={cdnMedia}
          branding={video.branding}
        />
        {socialMediaSection}
        <div className="large-margin-bottom">
          <ShareLink videoLink={ videoUrlWithoutToken }/>
        </div>
        {orderJobStatus !== ARCHIVED && (
          <GoBack
            token={token}
            videoId={videoId}
            updateVideoActions={updateVideoActions}
          />
        )}
      </div>
    );
  }
}

export default DownloadAndShare;
