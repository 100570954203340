import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import bootbox from "bootbox";
import axios from "../_config/axios";

class GoBack extends React.Component {
  constructor(props) {
    super(props);

    this.handleGoBackClick = this.handleGoBackClick.bind(this);
  }

  handleGoBackClick(e) {
    e.preventDefault();

    bootbox.confirm(
      "Are you sure you want to go back?<br/>If you have captions in this video, you might need to re-order or re-upload the caption again.",
      result => {
        if (!result) return;

        const { videoId, token, updateVideoActions } = this.props;

        axios
          .patch(`${process.env.REACT_APP_API_URL}videos/${videoId}/back`, {
            token
          })
          .then(() => updateVideoActions());
      }
    );
  }

  render() {
    return (
      <Row>
        <Col>
          <div className="small-margin-right small-margin-bottom">
            Didn't mean to approve?
          </div>
          <Button variant="danger" onClick={this.handleGoBackClick}>
            Go Back
          </Button>
        </Col>
      </Row>
    );
  }
}

export default GoBack;
