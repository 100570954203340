import bootbox from 'bootbox';

/**
 * Convert a number of seconds to 00:00:00 format.
 * For example, 10 seconds to 00:00:10.
 *
 * @param {number} initialTimeInSeconds
 * @returns {string}
 */
export function timeCode(initialTimeInSeconds) {
    if (initialTimeInSeconds.toString().trim() === '') return '';
    
    let sec_num = parseInt(initialTimeInSeconds, 10); // don't forget the second param
    let frames = parseInt((initialTimeInSeconds - sec_num) * 25);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
        hours = "0" + hours;
    }

    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    if (seconds < 10) {
        seconds = "0"+seconds;
    }

    return hours+':'+minutes+':'+seconds+':'+frames;
}

/**
 * Get an array of error messages from the response data.
 * Input got a format like:
 * {field1: ['Error 1 for field 1.', 'Error 2 for field 1.'], field2: ['Error 1 for field 2.', 'Error 2 for field 2.']}
 *
 * @param {Object} data
 * @return {Array}
 */
export function validationErrors(data) {
    return Object.values(data).flat();
}

/**
 * Get the customised error messages to be shown in bootbox.
 *
 * @param {Object} data
 */
export function validationErrorMessages(data) {
    let messages = validationErrors(data)
        .map(errorMessage => `<li>${errorMessage}</li>`)
        .join('');

    return `<ul style="color: red">${messages}</ul>`;
}

/**
 * Let the user know that part the change request such as annotations or attachments,
 * have been saved, but the request itself has not been finalised.
 *
 * @return void
 */
export function alertSaveButNotSubmitted() {
    bootbox.alert(`
        <div>Saved but not submitted.</div>
        <div>Make sure you finalise all your changes and click submit so we can get started on editing these for you.</div>
        <div>You and your team can all add changes and continue to save until you are ready to submit.</div>`
    );
}