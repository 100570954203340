import React from "react";
import { Button, Form, Card, Spinner } from "react-bootstrap";
import { CardElement, injectStripe } from "react-stripe-elements";
import axios from "../../_config/axios";
import qs from "query-string";
import bootbox from "bootbox";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: "",
      emailAddress: "",
      cardToken: null,
      processingPayment: false,
      paymentComplete: false,
      paymentFailed: false
    };

    this.submit = this.submit.bind(this);
  }

  handleInputChange = event => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  handleCardUpdate = e => {
    if (e.complete) {
      this.props.stripe.createToken().then(response => {
        this.setState({ cardToken: response.token });
      });
    } else {
      this.setState({ cardToken: null });
    }
  };

  isCardDomestic = () => {
    const cardToken = this.state.cardToken;

    return cardToken && cardToken.card && cardToken.card.country === "AU";
  };

  calculateTotalOrder = () => {
    const feePercent = this.isCardDomestic() ? 0.0175 : 0.029;
    const orderAmount = parseFloat(this.props.subTotal);

    // 1.1 is the GST amount, we need to add it to base price.
    // 0.30 is fixed fee in stripe, and feePercent is variable fee in stripe.
    // The formula being used here is to get total price we need to charge that includes stripe fee.
    const total = (orderAmount * 1.1 + 0.3) / (1 - feePercent);

    return total.toFixed(2);
  };

  handleError = () => {
    this.setState({
      processingPayment: false,
      paymentComplete: false,
      paymentFailed: true
    });
  };

  async submit(e) {
    e.preventDefault();

    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(this.state.emailAddress)) {
      bootbox.alert({
        message:
          "Invalid email address. <br/>Please make sure you have a valid email address."
      });

      return false;
    }

    // Todo
    // Send ajax call to server with total and make sure the price is correctly calculated.

    const key = process.env.REACT_APP_STRIPE_SECRET;
    const stripeAuthHeader = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${key}`
    };

    this.setState({ processingPayment: true });

    this.props.stripe
      .createPaymentMethod("card", {
        billing_details: {
          name: this.state.fullName,
          email: this.state.emailAddress
        }
      })
      .then(response => {
        const intentsParams = {
          payment_method: response.paymentMethod.id,
          amount: +(this.calculateTotalOrder() * 100).toFixed(2), // Change dollars to cents.
          currency: "aud",
          confirm: true,
          confirmation_method: "automatic",
          receipt_email: this.state.emailAddress,
          description: `${this.props.videoData.title}: Video Caption`
        };

        const metadata = {
          videoId: this.props.videoData.id,
          title: this.props.videoData.title,
          videoToken: this.props.token,
          languages: this.props.orderDetails.caption_languages,
          originUrl: window.location.href
        };

        for (const key in metadata) {
          if (metadata.hasOwnProperty(key)) {
            const keyName = ["metadata", "[", key, "]"].join("");
            intentsParams[keyName] = metadata[key];
          }
        }

        // Create payment intent to stripe.
        axios
          .post(
            `https://api.stripe.com/v1/payment_intents`,
            qs.stringify(intentsParams),
            { headers: stripeAuthHeader }
          )
          .then(response => {
            // Send API call to VMS to process the caption order.
            const ordersUrl = `${process.env.REACT_APP_API_URL}videos/${this.props.videoId}/captions/order`;

            axios
              .post(ordersUrl, {
                caption_languages: this.props.orderDetails.caption_languages,
                stripe_charge_details: JSON.stringify(
                  response.data.charges.data[0]
                ),
                order_subtotal: this.props.subTotal,
                token: this.props.token
              })
              .then(orderCaptionResponse => {
                if (orderCaptionResponse) {
                  this.setState({
                    processingPayment: false,
                    paymentComplete: true
                  });
                }
              })
              .catch(error => {
                this.handleError();
              });
          })
          .catch(error => {
            this.handleError();
          });
      })
      .catch(error => {
        this.handleError();
      });
  }

  renderUserInfoForm() {
    return (
      <>
        <Form.Row>
          <Form.Label>
            Full Name: <span className="red">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            className="medium-margin-bottom"
            value={this.state.fullName}
            name="fullName"
            onChange={e => this.handleInputChange(e)}
          />
        </Form.Row>
        <Form.Row>
          <Form.Label>
            Email: <span className="red">*</span>
          </Form.Label>
          <Form.Control
            type="email"
            className="medium-margin-bottom"
            value={this.state.emailAddress}
            name="emailAddress"
            onChange={e => this.handleInputChange(e)}
          />
        </Form.Row>
      </>
    );
  }

  retryPayment = e => {
    e.preventDefault();

    this.setState({
      fullName: "",
      emailAddress: "",
      cardToken: null,
      processingPayment: false,
      paymentComplete: false,
      paymentFailed: false
    });
  };

  renderCardForm() {
    return (
      <div className="card-form-wrapper">
        <Form.Label>Card Details</Form.Label>
        <div className="card-details-wrapper">
          <CardElement
            hidePostalCode={true}
            onChange={e => this.handleCardUpdate(e)}
            style={{ base: { fontSize: "15px" } }}
          />
        </div>
      </div>
    );
  }

  renderCompletePayment() {
    return (
      <>
        <div className="caption--body medium-margin-bottom">
          <Card.Title>Payment Successful!</Card.Title>
          <Card.Subtitle className="caption--sub-heading">
            Thank you! We have received your payment. You will receive an email
            once video caption(s) are ready.
          </Card.Subtitle>
        </div>
      </>
    );
  }

  renderFailedPayment() {
    return (
      <>
        <div className="caption--body medium-margin-bottom">
          <Card.Title>Unable to process payment!</Card.Title>
          <Card.Subtitle className="caption--sub-heading">
            There seems to be an error when we were trying to process your
            payment. Please contact your relationship manager if error persist.
          </Card.Subtitle>
        </div>
        <div className="caption--footer">
          <div className="caption--footer--submit">
            <Button
              className="caption--footer--submit-btn"
              onClick={e => {
                this.retryPayment(e);
              }}
            >
              Go Back
            </Button>
          </div>
        </div>
      </>
    );
  }

  render() {
    const isPaymentDone =
      this.state.paymentComplete && !this.state.processingPayment;
    const isPaymentFailed =
      this.state.paymentFailed && !this.state.processingPayment;
    const showPayButton =
      this.state.cardToken && this.state.fullName && this.state.emailAddress;

    if (isPaymentDone) {
      return this.renderCompletePayment();
    } else if (isPaymentFailed) {
      return this.renderFailedPayment();
    } else {
      return (
        <>
          <div className="caption--body medium-margin-bottom">
            <Form.Group controlId="formGridCaptionLanguages">
              <div className="checkout">
                <Form.Group>
                  {this.renderUserInfoForm()}
                  {this.renderCardForm()}
                </Form.Group>
              </div>
            </Form.Group>
          </div>
          <div className="caption--footer">
            <div className="caption--footer--info medium-margin-bottom">
              <div className="videoLength">
                <div className="caption--footer--info-label small-margin-bottom">
                  Sub Total: ${this.props.subTotal}
                </div>
                {this.state.cardToken ? (
                  <div className="caption--footer--info-text">
                    Transaction fee: {this.isCardDomestic() ? "1.75" : "2.9"}% +
                    $0.30
                    <br />
                    {!this.isCardDomestic() ? (
                      <>
                        International credit card fees may apply* <br />
                      </>
                    ) : null}
                    Prices are including GST
                  </div>
                ) : null}
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="caption--footer--submit">
              <Button
                className="caption--footer--submit-btn small-margin-right"
                variant="primary"
                disabled={this.state.processingPayment}
                onClick={() => this.props.hidePaymentModal()}
              >
                Back
              </Button>
              {showPayButton ? (
                <Button
                  className="caption--footer--submit-btn"
                  disabled={this.state.processingPayment}
                  onClick={e => {
                    this.submit(e);
                  }}
                >
                  {!this.state.processingPayment ? (
                    "Order Now - A$" + this.calculateTotalOrder()
                  ) : (
                    <>
                      <span className="small-margin-right">
                        Processing Payment
                      </span>
                      <Spinner as="span" animation="border" size="sm" />
                    </>
                  )}
                </Button>
              ) : null}
            </div>
            <div className="clearfix"></div>
          </div>
        </>
      );
    }
  }
}

export default injectStripe(CheckoutForm);
