import React from "react";
import CaptionMenu from "./CaptionMenu";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Form,
  Card
} from "react-bootstrap";

class OrderCaption extends React.Component {
  getDurationText = () => {
    let duration = this.props.videoDuration;

    let hours = Math.floor(duration / 3600);
    let minutesLeft = duration % 3600;
    let minutes = Math.floor(minutesLeft / 60);
    let seconds = Math.floor(minutesLeft % 60);
    seconds = seconds >= 10 ? seconds : `0${seconds}`;

    let durationText =
      (hours > 0 ? hours + " hour " : "") +
      (minutes > 0 ? minutes + " minutes " : "") +
      (seconds > 0 ? seconds + " seconds " : "");

    return durationText;
  };

  render() {
    return (
      <>
        <div className="caption--body medium-margin-bottom">
          <Form.Group controlId="formGridCaptionLanguages">
            <CaptionMenu
              selectedMenu={this.props.selectedMenu}
              selectMenu={this.props.selectMenu}
            />
            <Card.Title className="caption--headings">
              Select a language
            </Card.Title>
            <Card.Subtitle className="caption--sub-heading">
              You may order multiple languages, and English is{" "}
              <strong>FREE</strong> with any foreign language purchase.
            </Card.Subtitle>
            <ButtonToolbar aria-label="Toolbar with button groups">
              {this.props.captionLanguages.map((language, index) => (
                <ButtonGroup
                  key={language.id}
                  className="caption--options-language--container"
                  aria-label={language.label}
                >
                  <Button
                    className={`caption--options-language--btn ${
                      this.props.getSelectedLanguages(language.id)
                        ? "selected"
                        : "unselected"
                    }`}
                    onClick={() => this.props.selectLanguages(language.id)}
                  >
                    <Card.Title className="caption--options-language--label">
                      {language.label}
                    </Card.Title>
                    <Card.Subtitle className="caption--options-language--price">
                      ${this.props.getLanguagePrice(language).toFixed(2)}
                    </Card.Subtitle>
                  </Button>
                </ButtonGroup>
              ))}
            </ButtonToolbar>
          </Form.Group>
        </div>
        <div className="caption--footer">
          <div className="caption--footer--info">
            <div className="videoLength">
              <span className="caption--footer--info-label">
                Video length:{" "}
              </span>
              <span className="caption--footer--info-text">
                {this.getDurationText()}
              </span>
            </div>
            <div>
              <span className="caption--footer--info-label">Turn around: </span>
              <span className="caption--footer--info-text">
                {this.props.orderLanguages.length > 1 ? "2 days" : "24 hours"}
              </span>
            </div>
          </div>
          <div className="caption--footer--submit">
            <Button
              className="caption--footer--submit-btn"
              variant="primary"
              onClick={() => this.props.showPaymentModal()}
            >
              Pay Now - A${this.props.subTotal}
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
      </>
    );
  }
}

export default OrderCaption;
