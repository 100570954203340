import React from "react";
import QueryString from "query-string";
import Loader from "./Loader";
import axios from "../_config/axios";
import VideoPlayer from "./VideoPlayer";

class VideoEmbed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    const query = QueryString.parse(this.props.location.search);
    const videoId = this.props.match.params.id;
    const token = query.token;

    this.setState({ videoId, token });

    // Load video data via api call.
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `videos/` +
          videoId +
          (token ? "?token=" + token : "")
      )
      .then(res => {
        const videoData = res.data;
        this.setState({ isLoading: false, videoData });
      })
      .catch(
        function(error) {
          this.setState({ isLoading: false, isValidVideo: false });
        }.bind(this)
      );
  }

  /**
   * Render loader.
   */
  renderLoader() {
    return (
      <div className="wide-container">
        <div className="text-center extra-large-margin-top">
          <Loader />
        </div>
      </div>
    );
  }

  /**
   * Render video.
   */
  renderVideo() {
    return <VideoPlayer video={this.state.videoData} embed={true} />;
  }

  render() {
    return this.state.isLoading ? this.renderLoader() : this.renderVideo();
  }
}

export default VideoEmbed;
