import React from 'react';
import bootbox from "bootbox";

class QrCode extends React.Component {
    constructor(props) {
        super(props);

        this.handleQRCodeClick = this.handleQRCodeClick.bind(this);
    }

    handleQRCodeClick() {
        const qrServiceUrl = `https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${this.props.videoUrlWithoutToken}`;

        bootbox.alert({
            title: 'QR Code',
            message: `<img id="dynamic" src="${qrServiceUrl}"/>`,
        });
    }

    render() {
        return <div className="bar-button hover-fade small-margin-bottom" title="QR Code" onClick={ this.handleQRCodeClick }>
            <i className="bar-button__icon fas fa-qrcode"></i><span className="bar-button__text">QR Code</span>
        </div>;
    }
}

export default QrCode;