import React from "react";
import { isEmpty } from "lodash";

class VideoPlayer extends React.Component {
  renderVideoCaptions() {
    const captions = this.props.video.video_captions || [];

    if (captions.length === 0) {
      return "";
    }

    return Object.values(captions).map(caption =>
      caption && caption.srt_content && caption.srt_content.length > 0 ? (
        <track
          kind="captions"
          src={caption.vtt_url}
          label={caption.caption_language.label}
          key={caption.id}
          default={caption.caption_language.code === "en-us" ? "default" : ""}
        />
      ) : (
        ""
      )
    );
  }

  renderVideoPlayer() {
    const revision = this.props.video.revision
      ? Object.entries(this.props.video.revision)
      : [];
    const source = [];

    let index = 1;
    for (let [type, url] of revision) {
      let videoType = "video/" + type;
      source.push(<source src={url} type={videoType} key={index++} />);
    }

    return this.props.embed ? (
      <video
        id="video_embed"
        controls
        preload="auto"
        poster={this.props.video.poster}
        className="video-embed video-js visdom-video"
      >
        {source}
        {this.renderVideoCaptions()}
      </video>
    ) : (
      <video
        id="html_video"
        controls
        preload="auto"
        poster={this.props.video.poster}
        className="modal-video video-js visdom-video"
      >
        {source}
        {this.renderVideoCaptions()}
      </video>
    );
  }

  componentDidMount() {
    // Init Wisdom player script.
    if (typeof window.videojs != "undefined") {
      const VisdomPlayer = window.videojs;

      if (this.props.embed) {
        VisdomPlayer("video_embed")
      } else {
        if (!isEmpty(this.props.video.branding) && !isEmpty(this.props.video.branding.favicon_logo_url)) {
          VisdomPlayer("html_video", {
            brandingLogoUrl: this.props.video.branding.favicon_logo_url,
          })
        } else {
          VisdomPlayer("html_video")
        }
      }
    }
  }

  render() {
    return this.props.embed ? (
      <div id="video-embed-content">{this.renderVideoPlayer()}</div>
    ) : (
      <div className="video-content">
        <div id="video-player">
          <div id="media">{this.renderVideoPlayer()}</div>
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
