import React from 'react';
import { isEmpty, split } from "lodash";

class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    renderDefault() {
        return (
            <footer>
                <div className="footer-contents clearfix">
                    <div className="wide-container">
                        <div className="extra-large-padding-top small-padding-bottom">
                            <div className="row">
                                <div className="col-sm-6 small-margin-bottom">
                                    <p className="social-icons">
                                        <a href="https://www.facebook.com/VisualDomainAustralia" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                                        <a href="https://www.youtube.com/user/VisualDomainMedia" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube" aria-hidden="true"></i></a>
                                        <a href="https://www.linkedin.com/company/visual-domain" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin" aria-hidden="true"></i></a>
                                        <a href="https://twitter.com/visualdomain" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter" aria-hidden="true"></i></a>
                                        <a href="https://www.instagram.com/visual.domain/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" aria-hidden="true"></i></a>
                                    </p>
                                    <p className="copy-right">&copy; Visual Domain Australia Pty Ltd / All Rights Reserved.</p>
                                    <p className="info-links">
                                        <a href="https://www.visualdomain.com.au/about-us" target="_blank" rel="noopener noreferrer">About Us</a><span className="extra-small-margin-left extra-small-margin-right">|</span>
                                        <a href="https://www.visualdomain.com.au/contact-us-now" target="_blank" rel="noopener noreferrer">Contact Us</a>
                                    </p>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <h4 className="text-uppercase">Contact Us</h4>
                                    <div className="account-manager-info">
                                        <p><a href="mailto:info@visualdomain.com.au">info@visualdomain.com.au</a></p>
                                        <p><a href="https://www.visualdomain.com.au" target="_blank" rel="noopener noreferrer">visualdomain.com.au</a></p>
                                        <p><a href="tel:1300040718">1300 040 718</a></p>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

    renderCustomBranding() {
        let facebookSection, youtubeSection, linkedinSection, twitterSection, instagramSection, emailSection, websiteSection, phoneSection;

        if (!isEmpty(this.props.branding.facebook_url)) {
            facebookSection = <a href={this.props.branding.facebook_url} style={{color: this.props.branding.main_text_color}} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
        }

        if (!isEmpty(this.props.branding.youtube_url)) {
            youtubeSection = <a href={this.props.branding.youtube_url} style={{color: this.props.branding.main_text_color}} target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube" aria-hidden="true"></i></a>
        }

        if (!isEmpty(this.props.branding.linkedin_url)) {
            linkedinSection = <a href={this.props.branding.linkedin_url} style={{color: this.props.branding.main_text_color}} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin" aria-hidden="true"></i></a>
        }

        if (!isEmpty(this.props.branding.twitter_url)) {
            twitterSection = <a href={this.props.branding.twitter_url} style={{color: this.props.branding.main_text_color}} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter" aria-hidden="true"></i></a>
        }

        if (!isEmpty(this.props.branding.instagram_url)) {
            instagramSection = <a href={this.props.branding.instagram_url} style={{color: this.props.branding.main_text_color}} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" aria-hidden="true"></i></a>
        }

        if (!isEmpty(this.props.branding.contact_email)) {
            emailSection = <p><a href={`mailto:${this.props.branding.contact_email}`} style={{color: this.props.branding.main_text_color}}>{this.props.branding.contact_email}</a></p>
        }

        if (!isEmpty(this.props.branding.website_url)) {
            var splitWebsite = split(this.props.branding.website_url, '://', 2)
            
            websiteSection = <p><a href={this.props.branding.website_url} style={{color: this.props.branding.main_text_color}} target="_blank" rel="noopener noreferrer">{splitWebsite[1]}</a></p>
        }

        if (!isEmpty(this.props.branding.contact_number)) {
            phoneSection= <p><a href={`tel:${this.props.branding.contact_number}`} style={{color: this.props.branding.main_text_color}}>{this.props.branding.contact_number}</a></p>
        }

        return (
            <footer>
                <div className="footer-contents clearfix" style={{ 
                    background: this.props.branding.main_color,
                    color: this.props.branding.main_text_color
                }}>
                    <div className="wide-container">
                        <div className="extra-large-padding-top small-padding-bottom">
                            <div className="row">
                                <div className="col-sm-6 small-margin-bottom">
                                    <p className="social-icons" style={{marginTop: '25px'}}>
                                        {facebookSection}
                                        {youtubeSection}
                                        {linkedinSection}
                                        {twitterSection}
                                        {instagramSection}
                                    </p>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <h4 className="text-uppercase">Contact Us</h4>
                                    <div className="account-manager-info" style={{borderColor: this.props.branding.main_text_color}}>
                                        {emailSection}
                                        {websiteSection}
                                        {phoneSection}
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

    renderFooterBranding() {
        return (this.props.branding.show_footer ? this.renderCustomBranding() : '')
    }

    render() {
        return (!isEmpty(this.props.branding) ? this.renderFooterBranding() : this.renderDefault())
    }
}

export default Footer;