import React from "react";
import { Button, Modal } from "react-bootstrap";
import CaptionOfferLogo from "../../_assets/images/closed-caption.png";
import $ from "jquery";

class CaptionOfferModal extends React.Component {
  constructor(props) {
    super(props);
  }

  handleModalClose = () => {
    this.props.closeCaptionOfferModal();
  };

  handleShowCaptionVideo = () => {
    this.props.showVideoModal();
  };

  handleOrderCaption = () => {
    this.props.closeCaptionOfferModal();
    $("#videoOrderCaptionButton").trigger("click");
  };

  render() {
    const { showCaptionOfferModal } = this.props;

    return (
      <Modal
        show={showCaptionOfferModal}
        onHide={() => this.handleModalClose()}
        dialogClassName="manage-captions-modal caption-offer-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="caption-offer-wrapper text-center">
            <div className="caption-offer-logo medium-margin-bottom">
              <img
                className="logo"
                src={CaptionOfferLogo}
                alt="Caption Offer Logo"
              />
            </div>
            <h1>Would you like to order captions for your video?</h1>
            <h2 className="large-margin-bottom">
              Captions can boost your video and increase your audience
              engagement
            </h2>
            <div
              className="caption-offer-video extra-large-margin-bottom"
              onClick={() => this.handleShowCaptionVideo()}
            >
              <i
                className="far fa-play-circle extra-small-margin-right"
                aria-hidden="true"
              ></i>
              How? Watch this 30 sec video
            </div>
            <div className="caption-offer-action">
              <Button
                onClick={() => this.handleOrderCaption()}
                className="medium-margin-bottom"
                variant="secondary"
                id="videoOrderCaptionPopup"
              >
                Order Captions
              </Button>
              <div className="clearfix"></div>
              <div
                className="standard-blue-link"
                onClick={() => this.handleModalClose()}
              >
                Thanks, Maybe Later
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CaptionOfferModal;
