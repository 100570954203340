import React from 'react';
import {Col, Row} from "react-bootstrap";
import {InlineShareButtons} from "sharethis-reactjs";

class ShareThis extends React.Component {
    render() {
        const { title, videoUrlWithoutToken } = this.props;

        return (
            <Row>
                <Col lg={12}>
                    <InlineShareButtons
                        config={{
                            alignment: 'left',
                            color: 'social',
                            enabled: true,
                            font_size: 16,
                            labels: null,
                            language: 'en',
                            networks: [
                                'twitter',
                                'linkedin',
                                'email'
                            ],
                            padding: 12,
                            radius: 4,
                            show_total: false,
                            size: 40,

                            // Optional Parameters
                            url: videoUrlWithoutToken,
                            title,
                            message: `${title} (${videoUrlWithoutToken})`,
                            subject: title
                        }}
                    />
                </Col>
            </Row>
        )
    }
}

export default ShareThis;