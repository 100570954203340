import React from 'react';
import {ListGroup} from "react-bootstrap";
import Attachment from './Attachment'

class Attachments extends React.Component {

    render() {
        const {attachments} = this.props;

        return <ListGroup variant="flush">
            {
                attachments.map(attachment => <Attachment key={attachment.id} attachment={attachment} removeAttachment={this.props.removeAttachment}/>)
            }
        </ListGroup>
    }
}

export default Attachments;