import React from 'react';
import {Row, Col, Form, Button, ButtonToolbar, Spinner} from 'react-bootstrap';
import {alertSaveButNotSubmitted, timeCode, validationErrorMessages} from "../helpers";
import bootbox from "bootbox";
import {ActionsContext} from "../_context/ActionsContext";

class AnnotationForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            from: "",
            to: "",
            comment: "",
            author: "",
            sendingAnnotation: false,
            addButtonText: 'Add'
        };

        this.recordTime = this.recordTime.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.processAnnotation = this.processAnnotation.bind(this);
    }

    recordTime(field) {
        const {html_video: primaryPlayer} = window.videojs.players;

        // If the start time has been recorded and the end time is empty, set the end time to be equal to the start time, too.
        if (field === 'from' && !this.state.to) {
            this.setState({
                from: primaryPlayer.currentTime(),
                to: primaryPlayer.currentTime()
            });
        } else {
            this.setState({
                [field]: primaryPlayer.currentTime()
            });
        }

        primaryPlayer.pause();
    }

    handleInputChange(event) {
        const {name, value} = event.target;

        this.setState({[name]: value});
    }

    processAnnotation() {
        const {sendAnnotation, willBeFirstAnnotation} = this.props;
        this.setState({
            sendingAnnotation: true,
            addButtonText: 'Adding...'
        });

        sendAnnotation({...this.state}).then(({data: newAnnotation}) => {
            this.context.addAnnotation(newAnnotation);

            if (willBeFirstAnnotation) {
                alertSaveButNotSubmitted();
            }

            this.setState({
                from: "",
                to: "",
                comment: ""
            });
        }).catch(({response}) => {
            // 1. if response got a status of 422, its validation error.
            if (response.status === 422) {
                bootbox.alert(
                    validationErrorMessages(response.data)
                );
            }
        }).finally(() => this.setState({sendingAnnotation: false, addButtonText: 'Add'}));
    }

    render() {
        return <React.Fragment>
            <Row className="small-margin-top">
                <Col>
                    <Form.Control name="from" value={timeCode(this.state.from)} disabled/>
                </Col>
                <Col>
                    <Form.Control name="to" value={timeCode(this.state.to)} disabled/>
                </Col>
            </Row>
            <Row className="small-margin-top">
                <Col>
                    <Button variant="success" className="full-width" disabled={this.state.sendingAnnotation} onClick={() => this.recordTime('from')}>Record Start Time</Button>
                </Col>
                <Col>
                    <Button variant="danger" className="full-width" disabled={this.state.sendingAnnotation} onClick={() => this.recordTime('to')}>Record End Time</Button>
                </Col>
            </Row>
            <Form.Group controlId="orderJobChangeAnnotation.comment" className="small-margin-top">
                <Form.Label>Comment <span className="red">*</span></Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5} cols={50}
                    name="comment"
                    onChange={this.handleInputChange}
                    value={this.state.comment}
                    disabled={this.state.sendingAnnotation}
                />
            </Form.Group>
            <Form.Group controlId="orderJobChangeAnnotation.author">
                <Form.Label>Comment added by <span className="red">*</span></Form.Label>
                <Form.Control
                    as="input"
                    type="text"
                    name="author"
                    placeholder="Leave your name here"
                    onChange={this.handleInputChange}
                    value={this.state.author}
                    disabled={this.state.sendingAnnotation}
                />
            </Form.Group>
            <ButtonToolbar className="large-margin-bottom">
                <Button variant="primary" onClick={this.processAnnotation} disabled={this.state.sendingAnnotation}>{this.state.addButtonText}</Button>&nbsp;&nbsp;
                {
                    this.state.sendingAnnotation &&
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                }

            </ButtonToolbar>
        </React.Fragment>
    }
}

AnnotationForm.contextType = ActionsContext;

export default AnnotationForm;