import React from 'react';
import Annotation from "./Annotation";

class Annotations extends React.Component {
    render() {
        const {annotations, removeAnnotation, updateAnnotation} = this.props;

        return <React.Fragment>
            {
                annotations.map(
                    (annotation, index) => <Annotation
                        key={index}
                        annotation={annotation}
                        removeAnnotation={removeAnnotation}
                        updateAnnotation={updateAnnotation}
                    />
                )
            }
        </React.Fragment>
    }
}

export default Annotations;