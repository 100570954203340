import React from "react";
import Loader from "./Loader";
import VideoDelivered from "./VideoDelivered";
import VideoApprovedOrArchived from "./VideoApprovedOrArchived";
import VideoDefault from "./VideoDefault";
import * as OrderJobStatus from "../_constants/orderJobStatus";
import * as VideoType from "../_constants/videoType";
import axios from "../_config/axios";
import VideoChangeRequest from "./VideoChangeRequest";
import VideoChangesScheduled from "./VideoChangesScheduled";
import VideoChangesUploaded from "./VideoChangesUploaded";
import VideoOnYoutube from "./VideoOnYoutube";
import InternalVideoDefault from "./InternalVideoDefault";
import { ActionsContext } from "../_context/ActionsContext";
import _ from "lodash";

class VideoActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      actionsData: {}
    };

    this.loadVideoActions = this.loadVideoActions.bind(this);
    this.resetAttachments = this.resetAttachments.bind(this);
    this.addAnnotation = this.addAnnotation.bind(this);
    this.detachAnnotation = this.detachAnnotation.bind(this);
    this.reviseAnnotations = this.reviseAnnotations.bind(this);
    this.detachAttachment = this.detachAttachment.bind(this);
    this.setOrderJobChangeDescription = this.setOrderJobChangeDescription.bind(
      this
    );
  }

  componentDidMount() {
    // Load video actions data via api call.
    if (
      this.props.token &&
      this.props.video.video_type === VideoType.ORDER_VIDEO
    ) {
      this.loadVideoActions();
    } else {
      this.setState({ isLoading: false });
    }
  }

  loadVideoActions() {
    const videoId = this.props.videoId;
    const token = this.props.token;

    this.setState({ isLoading: true });

    axios
      .get(
        process.env.REACT_APP_API_URL +
          `videos/` +
          videoId +
          "/actions" +
          (token ? "?token=" + token : "")
      )
      .then(res => {
        const actionsData = res.data;
        this.setState({ isLoading: false, actionsData });
      })
      .catch(
        function() {
          this.setState({ isLoading: false });
        }.bind(this)
      );
  }

  renderLoader() {
    return (
      <div className="wide-container">
        <div className="text-center extra-large-margin-top">
          <Loader />
        </div>
      </div>
    );
  }

  setOrderJobChangeDescription(text) {
    let actionsData = { ...this.state.actionsData };

    actionsData.order_job_change.description = text;

    this.setState({ actionsData });
  }

  resetAttachments(attachments) {
    let actionsData = { ...this.state.actionsData };

    actionsData.order_job_change.attachments = attachments;

    this.setState({ actionsData });
  }

  detachAttachment(removedAttachment) {
    let actionsData = { ...this.state.actionsData };

    _.remove(actionsData.order_job_change.attachments, attachment => {
      return attachment.id === removedAttachment.id;
    });

    this.setState({ actionsData });
  }

  addAnnotation(newAnnotation) {
    let actionsData = { ...this.state.actionsData };

    actionsData.order_job_change.order_job_change_annotations.unshift(
      newAnnotation
    );

    this.setState({ actionsData });
  }

  detachAnnotation(removedAnnotation) {
    let actionsData = { ...this.state.actionsData };

    _.remove(
      actionsData.order_job_change.order_job_change_annotations,
      annotation => {
        return annotation.id === removedAnnotation.id;
      }
    );

    this.setState({ actionsData });
  }

  reviseAnnotations(updatedAnnotation) {
    let actionsData = { ...this.state.actionsData };
    let annotations = actionsData.order_job_change.order_job_change_annotations;
    let updatedIndex = _.findIndex(
      annotations,
      annotation => annotation.id === updatedAnnotation.id
    );
    annotations.splice(updatedIndex, 1, updatedAnnotation);

    this.setState({ actionsData });
  }

  renderActions() {
    if (this.props.video.video_type === VideoType.ORDER_VIDEO) {
      switch (this.state.actionsData.order_job_status) {
        case OrderJobStatus.DELIVERED:
          return (
            <VideoDelivered
              updateVideoActions={this.loadVideoActions}
              actions={this.state.actionsData}
              videoId={this.props.videoId}
              token={this.props.token}
            />
          );
        case OrderJobStatus.CHANGES:
          return (
            <ActionsContext.Provider
              value={{
                resetAttachments: this.resetAttachments,
                addAnnotation: this.addAnnotation,
                detachAnnotation: this.detachAnnotation,
                reviseAnnotations: this.reviseAnnotations,
                detachAttachment: this.detachAttachment,
                setOrderJobChangeDescription: this.setOrderJobChangeDescription
              }}
            >
              <VideoChangeRequest
                updateVideoActions={this.loadVideoActions}
                actions={this.state.actionsData}
                videoId={this.props.videoId}
                token={this.props.token}
              />
            </ActionsContext.Provider>
          );
        case OrderJobStatus.CHANGES_SCHEDULED:
          return <VideoChangesScheduled />;
        case OrderJobStatus.CHANGES_UPLOADED:
          return <VideoChangesUploaded />;
        case OrderJobStatus.APPROVED:
        case OrderJobStatus.ARCHIVED:
          return (
            <VideoApprovedOrArchived
              updateVideoActions={this.loadVideoActions}
              actions={this.state.actionsData}
              token={this.props.token}
              video={this.props.video}
            />
          );
        case OrderJobStatus.ON_YOUTUBE:
          return <VideoOnYoutube />;
        default:
          return <VideoDefault />;
      }
    } else {
      return (
        <InternalVideoDefault
          token={this.props.token}
          video={this.props.video}
          videoActions={this.state.actionsData}
          updateVideoActions={this.loadVideoActions}
        />
      );
    }
  }

  render() {
    return this.state.isLoading ? this.renderLoader() : this.renderActions();
  }
}

export default VideoActions;
