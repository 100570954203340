import React from 'react';
import { Row, Col, Button } from "react-bootstrap";
import Loader from "./Loader";
import axios from '../_config/axios';
import bootbox from 'bootbox';

class VideoDelivered extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };

        this.handleChangeRequestClick = this.handleChangeRequestClick.bind(this);
        this.handleApproveVideoClick = this.handleApproveVideoClick.bind(this);
        this.handleVideoChangeRequest = this.handleVideoChangeRequest.bind(this);
        this.handleVideoApproveRequest = this.handleVideoApproveRequest.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    handleChangeRequestClick() {
        bootbox.confirm("Let's get started.<br/>If you would like other members of the team to make changes, you can do so by sending them the link to this page. Anyone can add comments and changes, and it won't be finalised until you press 'submit'.", this.handleVideoChangeRequest);
    }

    handleVideoChangeRequest(result) {
        if (!result) {
            return;
        }

        this.setState({ isLoading: true });

        const {videoId, token, updateVideoActions} = this.props;

        // Send an API call.
        axios.post(process.env.REACT_APP_API_URL + `videos/` +  videoId + '/order-job-changes', {token})
            .then(() => {
                updateVideoActions();
            })
            .catch(({response}) => {
                bootbox.alert(response.data.message);
                this.setState({ isLoading: false });
            });
    }

    handleApproveVideoClick() {
        bootbox.confirm("Hooray! Let's start sharing!<br/>Once approved you'll receive a final link.<br/>Any additional changes after this point may incur a cost.", this.handleVideoApproveRequest);
    }

    handleVideoApproveRequest(result) {
        if (!result) {
            return;
        }

        this.setState({ isLoading: true });

        const {videoId, token, updateVideoActions} = this.props;

        axios.patch(process.env.REACT_APP_API_URL + `videos/` +  videoId + '/approved', { token })
            .then(() => {
                updateVideoActions();
            })
            .catch(({response}) => {
                this.setState({ isLoading: false });
            });

    }

    /**
     * Render loader.
     */
    renderLoader() {
        return (
            <div className="wide-container">
                <div className="text-center extra-large-margin-top">
                    <Loader />
                </div>
            </div>
        );
    }

    /**
     * Render video.
     */
    renderVideo() {
        return (
            <div id="change-or-approve">
                <Row>
                    { this.props.actions.changes ?
                        <Col sm={12} className="extra-small-margin-bottom">
                            <h4>Welcome back</h4>
                            <p>Here is your updated video. If you're happy with the changes that have been made you can go ahead and approve it. Otherwise, if you'd like to make more changes, follow the change request process again, but please be aware that this may incur a fee. Please speak with your Visual Domain Contact if you are unsure of the cost.</p>
                        </Col>:
                        <Col sm={12} className="extra-small-margin-bottom">
                            <h4><strong>We loved making this video!</strong></h4>
                            <p>Let us know if you’re happy with the video or you would like to request a change.</p>
                        </Col>
                    }
                    <Col sm={12} className="medium-margin-bottom">
                        <div className="small-margin-bottom">
                            <Button variant="warning" onClick={ this.handleChangeRequestClick }>Request Change</Button>
                        </div>
                        <div className="small-margin-bottom">
                            <Button variant="success" onClick={ this.handleApproveVideoClick }>Approve Video</Button>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <h4>What happens next?</h4>
                        <p>Once your video is approved, you can download and share it with the world! We can also track the views of your video - the statistics will be available for you to see on our client dashboard. </p>
                        <a href={ process.env.REACT_APP_CLIENT_DASHBOARD_URL + 'stats' } target="_blank" rel="noopener noreferrer"><Button variant="success">Sign in to your account</Button></a>
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        return (this.state.isLoading) ? this.renderLoader() : this.renderVideo();
    }
}

export default VideoDelivered;