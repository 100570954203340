import React from "react";
import { Form, ButtonToolbar, Button, Spinner } from "react-bootstrap";
import bootbox from "bootbox";
import Attachments from "./Attachments";
import { validationErrorMessages } from "../helpers";
import { ActionsContext } from "../_context/ActionsContext";

class RequestChangesBasicForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      description: "",
      submissionText: "",
      selectedFile: null,
      typingTimeout: 0,
      attachmentsUploading: false
    };

    this.updateDescription = this.updateDescription.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.alertDescriptionUpdated = this.alertDescriptionUpdated.bind(this);
    this.confirmSubmitChanges = this.confirmSubmitChanges.bind(this);
  }

  componentDidMount() {
    if (this.props.orderJobChange.description != null) {
      this.setState({
        description: this.props.orderJobChange.description
      });
    }
  }

  updateDescription() {
    const { description } = this.state;

    if (description === this.props.orderJobChange.description) {
      return;
    }

    this.setState({ submissionText: "Saving..." });

    this.props
      .updateOrderJobChange({ description })
      .then(() => {
        this.alertDescriptionUpdated();
      })
      .catch(({ response }) => {
        this.alertDescriptionUpdated(response.data.message);
      });
  }

  alertDescriptionUpdated() {
    this.setState({ submissionText: "Saved" });
    this.context.setOrderJobChangeDescription(this.state.description);

    setTimeout(() => {
      this.setState({ submissionText: "" });
    }, 5000);
  }

  handleInputChange(event) {
    const { name, value } = event.target;

    this.setState({ [name]: value });

    if (name === "description") {
      if (this.state.typingTimeout) {
        clearTimeout(this.state.typingTimeout);
      }
      this.setState({
        typingTimeout: setTimeout(
          function() {
            this.updateDescription();
          }.bind(this),
          1000
        )
      });
    }

    if (name === "selectedFile") {
      if (event.target.files.length > 0) {
        let files = Object.values(event.target.files);
        let largeFile = files.find(file => file.size > 100000000);
        event.target.value = null;

        if (largeFile) {
          bootbox.alert(
            "One of the files exceed the server limit. Please upload file less than 100MB."
          );
          return;
        }

        this.setState({ attachmentsUploading: true });

        this.props
          .upload(files)
          .then(({ data: newAttachments }) => {
            bootbox.alert(`File(s) have been uploaded successfully.`);
            this.context.resetAttachments(newAttachments);
          })
          .catch(error => {
            if (error.response === undefined) {
              bootbox.alert(
                "The file exceed the server limit. Please upload file less than 100MB."
              );
              return;
            }

            if (error.response.status === 422) {
              bootbox.alert(validationErrorMessages(error.response.data));
            }
          })
          .finally(() => {
            this.setState({ attachmentsUploading: false });
          });
      } else {
        bootbox.alert("No file has been selected.");
      }
    }
  }

  confirmSubmitChanges() {
    if (!this.props.validateDescription()) return;

    bootbox.confirm(
      `<div>Warning!</div> 
            <div>Are you sure you want to submit? Once you submit, you and anyone else will no longer be able to edit this round of changes. If you submit and want to add further changes later it may incur a fee.</div>`,
      result => {
        if (result) {
          this.props.submitChanges();
        }
      }
    );
  }

  render() {
    const { orderJobChange } = this.props;

    return (
      <React.Fragment>
        <Form.Group controlId="orderJobChange.description">
          <Form.Label>General Comments</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            cols={50}
            name="description"
            onChange={this.handleInputChange}
            value={this.state.description}
          />
          <Form.Text className="text-muted">
            {this.state.submissionText}
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="orderJobChange.attachments">
          <Form.Label>
            Attachments &nbsp;&nbsp;
            {this.state.attachmentsUploading && (
              <Spinner animation="border" role="status" size="sm" as="span">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
          </Form.Label>
          <Form.Control
            as="input"
            type="file"
            name="selectedFile"
            onChange={this.handleInputChange}
            multiple="multiple"
            disabled={this.state.attachmentsUploading}
          />
        </Form.Group>
        <Attachments
          attachments={orderJobChange.attachments}
          removeAttachment={this.props.removeAttachment}
        />
        <ButtonToolbar className="small-margin-top">
          <Button
            variant="success"
            disabled={this.state.attachmentsUploading}
            onClick={this.confirmSubmitChanges}
          >
            Submit Changes
          </Button>
        </ButtonToolbar>
      </React.Fragment>
    );
  }
}

RequestChangesBasicForm.contextType = ActionsContext;

export default RequestChangesBasicForm;
