import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import Loader from "./Loader";
import axios from "../_config/axios";
import bootbox from "bootbox";
import RequestChangesAdvancedForm from "./RequestChangesAdvancedForm";
import { validationErrorMessages } from "../helpers";
import { ClientIsReady } from "./General";
import { ActionsContext } from "../_context/ActionsContext";

class VideoChangeRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };

    this.handleGoBackClick = this.handleGoBackClick.bind(this);
    this.goBack = this.goBack.bind(this);
    this.renderChanges = this.renderChanges.bind(this);
    this.renderChangesForm = this.renderChangesForm.bind(this);
    this.updateOrderJobChange = this.updateOrderJobChange.bind(this);
    this.upload = this.upload.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.submitChanges = this.submitChanges.bind(this);
    this.sendAnnotation = this.sendAnnotation.bind(this);
    this.removeAnnotation = this.removeAnnotation.bind(this);
    this.updateAnnotation = this.updateAnnotation.bind(this);
    this.validateDescription = this.validateDescription.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  handleGoBackClick(e) {
    e.preventDefault();

    bootbox.confirm(
      '<p class="p-lg">Warning:</p><p>By going back, you will lose all the changes you made.</p><p>Are you sure you want to go back?</p>',
      this.goBack
    );
  }

  goBack(result) {
    if (!result) {
      return;
    }

    this.setState({ isLoading: true });

    const { videoId, token, actions } = this.props;
    const { order_job_change: orderJobChange } = actions;

    axios
      .patch(`${process.env.REACT_APP_API_URL}videos/${videoId}/back`, {
        token: token,
        order_job_change_id: orderJobChange.id
      })
      .then(() => {
        this.props.updateVideoActions();
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  updateOrderJobChange(input) {
    const { actions, token } = this.props;
    const { order_job_change: orderJobChange } = actions;

    return axios.patch(
      `${process.env.REACT_APP_API_URL}order-job-changes/${orderJobChange.id}`,
      { ...input, token }
    );
  }

  upload(attachments) {
    let formData = new FormData();
    const { actions, token } = this.props;
    const { order_job_change: orderJobChange } = actions;

    attachments.forEach(attachment =>
      formData.append("order_job_change_attachments[]", attachment)
    );

    formData.append("token", token);

    return axios.post(
      `${process.env.REACT_APP_API_URL}order-job-changes/${orderJobChange.id}/attachments`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
  }

  removeAttachment(attachment) {
    const { actions, token } = this.props;
    const { order_job_change: orderJobChange } = actions;

    return axios.delete(
      `${process.env.REACT_APP_API_URL}order-job-changes/${orderJobChange.id}/attachments/${attachment.id}?token=${token}`
    );
  }

  submitChanges() {
    const { actions, token } = this.props;
    const { order_job_change: orderJobChange } = actions;

    this.setState({ isLoading: true });

    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}order-job-changes/${orderJobChange.id}`,
        {
          token,
          is_client_ready: 1
        }
      )
      .then(() => this.props.updateVideoActions())
      .finally(() => this.setState({ isLoading: false }));
  }

  validateDescription() {
    const { order_job_change: orderJobChange } = this.props.actions;

    // General comments field cannot be empty if the annotation list is also empty.
    if (
      orderJobChange.order_job_change_annotations.length === 0 &&
      !orderJobChange.description
    ) {
      bootbox.alert(
        validationErrorMessages({
          description: [
            "Please follow the steps to add comments OR add a general comment before submitting."
          ]
        })
      );
      return false;
    }

    return true;
  }

  sendAnnotation({ from, to, author, comment }) {
    const { actions, token } = this.props;
    const { order_job_change: orderJobChange } = actions;

    return axios.post(
      `${process.env.REACT_APP_API_URL}order-job-change-annotations`,
      {
        token,
        from,
        to,
        author_name: author,
        comment,
        order_job_change_id: orderJobChange.id
      }
    );
  }

  updateAnnotation({ comment, id }) {
    const { token } = this.props;

    return axios.patch(
      process.env.REACT_APP_API_URL + `order-job-change-annotations/${id}`,
      { comment, token }
    );
  }

  removeAnnotation(annotationId) {
    const { token } = this.props;

    return axios.delete(
      process.env.REACT_APP_API_URL +
        "order-job-change-annotations/" +
        annotationId +
        "?token=" +
        token
    );
  }

  renderLoader() {
    return (
      <div className="wide-container">
        <div className="text-center extra-large-margin-top">
          <Loader />
        </div>
      </div>
    );
  }

  renderChangesForm() {
    const goBack = this.renderGoBack();

    return this.props.actions.order_job_change.is_client_ready ? (
      <> {ClientIsReady()}</>
    ) : (
      <div>
        <Row>
          <Col>
            <Form>
              <RequestChangesAdvancedForm
                orderJobChange={this.props.actions.order_job_change}
                sendAnnotation={this.sendAnnotation}
                removeAnnotation={this.removeAnnotation}
                updateAnnotation={this.updateAnnotation}
                updateOrderJobChange={this.updateOrderJobChange}
                updateVideoActions={this.props.updateVideoActions}
                upload={this.upload}
                removeAttachment={this.removeAttachment}
                submitChanges={this.submitChanges}
                validateDescription={this.validateDescription}
              />
            </Form>
          </Col>
        </Row>
        {goBack}
      </div>
    );
  }

  renderGoBack() {
    return (
      <Row>
        <Col className="large-margin-top">
          <div className="small-margin-right small-margin-bottom">
            Didn't need to make changes?
          </div>
          <Button variant="danger" onClick={this.handleGoBackClick}>
            Go Back
          </Button>
        </Col>
      </Row>
    );
  }

  renderNoOpenChanges() {
    return (
      <Row>
        <Col className="red">
          There's no change opened, Visual Domain Team is re-uploading video
        </Col>
      </Row>
    );
  }

  renderChanges() {
    return this.props.actions.order_job_change
      ? this.renderChangesForm()
      : this.renderNoOpenChanges();
  }

  render() {
    return this.state.isLoading ? this.renderLoader() : this.renderChanges();
  }
}

VideoChangeRequest.contextType = ActionsContext;

export default VideoChangeRequest;
