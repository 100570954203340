import React from "react";
import { Button } from "react-bootstrap";
import axios from "../_config/axios";
import RevisionHistoryModal from "./RevisionHistoryModal";
import ManageCaptionsModal from "./captions/ManageCaptionsModal";
import CaptionVideoModal from "./captions/CaptionVideoModal";
import { CaptionContext } from "../_context/CaptionContext";
import { isEmpty } from "lodash";

class VideoActionButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      showModal: false,
      openModal: this.openModal,
      closeModal: this.closeModal,
      showCaptionsModal: false,
      openCaptionsModal: this.openCaptionsModal,
      closeCaptionsModal: this.closeCaptionsModal,
      showVideoModal: false,
      videoRevisions: [],
      captionLanguages: [],
      selectedRevision: {},
      videoDuration: 0
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getAllRevisionsUrl = this.getAllRevisionsUrl.bind(this);
    this.loadRevisions = this.loadRevisions.bind(this);
    this.loadCaptionLanguages = this.loadCaptionLanguages.bind(this);
    this.setSelectedRevision = this.setSelectedRevision.bind(this);

    this.openCaptionsModal = this.openCaptionsModal.bind(this);
    this.closeCaptionsModal = this.closeCaptionsModal.bind(this);
    this.showVideoModal = this.showVideoModal.bind(this);
    this.closeVideoModal = this.closeVideoModal.bind(this);
  }

  loadRevisions() {
    axios
      .get(this.getAllRevisionsUrl())
      .then(response => {
        // The array of video revisions are inside the data key of the data part of the response.
        const videoRevisions = response.data.data;

        this.setState({
          isLoading: false,
          videoRevisions: [...videoRevisions],
          // The selected revision is the latest one by default after all the revisions are loaded.
          selectedRevision: videoRevisions.shift()
        });
      })
      .catch(
        function() {
          this.setState({ isLoading: false });
        }.bind(this)
      );
  }

  getAllRevisionsUrl() {
    const { videoId, token } = this.props;

    return (
      process.env.REACT_APP_API_URL +
      `videos/` +
      videoId +
      "/revisions" +
      (token ? "?token=" + token : "")
    );
  }

  loadCaptionLanguages() {
    axios
      .get(process.env.REACT_APP_API_URL + `videos/captions/languages`)
      .then(response => {
        // The array of caption languages are inside the data key of the data part of the response.
        const captionLanguages = response.data;

        this.setState({
          isLoading: false,
          captionLanguages: [...captionLanguages]
        });
      })
      .catch(
        function() {
          this.setState({ isLoading: false });
        }.bind(this)
      );
  }

  openModal() {
    if (this.state.videoRevisions.length === 0) {
      this.loadRevisions();
    }

    // Pause the main video player.
    const { html_video: mainVideoPlayer } = window.videojs.players;
    mainVideoPlayer.pause();

    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openCaptionsModal() {
    if (this.state.captionLanguages.length === 0) {
      this.loadCaptionLanguages();
    }

    // Pause the main video player.
    const { html_video: mainVideoPlayer } = window.videojs.players;
    mainVideoPlayer.pause();

    this.setState({
      showCaptionsModal: true,
      videoDuration: mainVideoPlayer.duration()
    });
  }

  closeCaptionsModal() {
    this.setState({ showCaptionsModal: false });
  }

  setSelectedRevision(selectedRevision) {
    this.setState({ selectedRevision });
  }

  showVideoModal() {
    // Pause the main video player.
    const { html_video: mainVideoPlayer } = window.videojs.players;
    mainVideoPlayer.pause();

    this.setState({ showVideoModal: true });
  }

  closeVideoModal() {
    this.setState({ showVideoModal: false });
  }

  render() {
    const { videoId, token, videoData } = this.props;
    let captionSection;
    let clientHubSection;

    if (!isEmpty(this.props.videoData.branding)) {
      if (this.props.videoData.branding.show_caption) {
        captionSection = <div className="float-left video-action-left">
          <Button
            onClick={this.openCaptionsModal}
            className="medium-margin-right small-margin-bottom"
            id="videoOrderCaptionButton"
            style={{ 
              backgroundColor: this.props.videoData.branding.sub_color,
              color: this.props.videoData.branding.sub_text_color
            }}
          >
            {videoData.video_captions.length === 0
              ? "Order Captions"
              : "Manage Captions"}
          </Button>
          <span
            className="caption-teaser small-margin-bottom d-inline-block align-middle"
            onClick={this.showVideoModal}
          >
            <i
              className="far fa-play-circle extra-small-margin-right"
              aria-hidden="true"
            ></i>
            Why? Watch this 30 sec video
          </span>
        </div>
      }
    } else {
      captionSection = <div className="float-left video-action-left">
        <Button
          onClick={this.openCaptionsModal}
          className="medium-margin-right small-margin-bottom"
          variant="secondary"
          id="videoOrderCaptionButton"
        >
          {videoData.video_captions.length === 0
            ? "Order Captions"
            : "Manage Captions"}
        </Button>
        <span
          className="caption-teaser small-margin-bottom d-inline-block align-middle"
          onClick={this.showVideoModal}
        >
          <i
            className="far fa-play-circle extra-small-margin-right"
            aria-hidden="true"
          ></i>
          Why? Watch this 30 sec video
        </span>
      </div>
    }

    if (isEmpty(this.props.videoData.branding) || this.props.videoData.branding.show_client_hub) {
      clientHubSection = <a
        href={process.env.REACT_APP_CLIENT_DASHBOARD_URL + "all-videos"}
        target="_blank"
        className="right-border"
        rel="noopener noreferrer"
      >
        See All Of Your Videos
      </a>
    } 
    
    return (
      <div>
        <div id="below-video-section" className="small-margin-top">
          {captionSection}
          <div className="float-right video-action-right">
            {clientHubSection}
            <a onClick={this.openModal}>View Revision History</a>
          </div>
        </div>
        <RevisionHistoryModal
          showModal={this.state.showModal}
          closeModal={this.closeModal}
          revisions={this.state.videoRevisions}
          selectedRevision={this.state.selectedRevision}
          setSelectedRevision={this.setSelectedRevision}
          branding={videoData.branding}
        />
        <CaptionContext.Provider
          value={{
            videoId: videoId,
            token: token,
            videoData: videoData,
            captionLanguages: this.state.captionLanguages,
            videoDuration: this.state.videoDuration
          }}
        >
          <ManageCaptionsModal
            showCaptionsModal={this.state.showCaptionsModal}
            closeCaptionsModal={this.closeCaptionsModal}
          />
        </CaptionContext.Provider>
        <CaptionVideoModal
          showVideoModal={this.state.showVideoModal}
          closeVideoModal={this.closeVideoModal}
        />
      </div>
    );
  }
}

export default VideoActionButton;
