import React from 'react';

class RevisionReview extends React.Component {
    render() {
        const {id, review} = this.props.review;

        return <div dangerouslySetInnerHTML={{__html: review}} key={id} className="border-bottom"/>
    }
}

export default RevisionReview;