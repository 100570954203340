import React from "react";
import { Modal, Form } from "react-bootstrap";
import Loader from "../Loader";
import { Elements, StripeProvider } from "react-stripe-elements";
import axios from "../../_config/axios";
import CheckoutForm from "./CheckoutForm";
import OrderCaption from "./OrderCaption";
import UploadCaption from "./UploadCaption";
import EditCaption from "./EditCaption";
import { CaptionContext } from "../../_context/CaptionContext";

class ManageCaptionsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      selectedTranscription: "order",
      selectedMenu: "order",
      orderLanguages: [1],
      additionalNotes: null,
      languageId: "1",
      captionFile: null,
      captionContent: null,
      canDisplayPaymentModal: false,
      canDisplayCaptionEditor: false,
      videoData: null,
      processingEdit: false,
      editComplete: false,
      editFailed: false,
      processingUpload: false,
      uploadComplete: false,
      uploadFailed: false,
      invalidFileType: false,
      modalTitle: "Order Closed Captioning"
    };

    this.selectLanguages = this.selectLanguages.bind(this);
    this.getSelectedLanguages = this.getSelectedLanguages.bind(this);
  }

  componentDidMount() {
    const videoData = this.context.videoData;

    this.setState({ isLoading: false, videoData });
  }

  availableLanguagesForEdit = () => {
    if (this.state.videoData) {
      const videoCaptions = this.state.videoData.video_captions;
      const captionsArray = [];

      Object.values(videoCaptions).forEach(videoCaption => {
        captionsArray.push(videoCaption.caption_language_id);
      });

      const availableLanguages = captionsArray.reduce(
        (uniqueCaptions, caption) => {
          return uniqueCaptions.includes(caption)
            ? uniqueCaptions
            : [...uniqueCaptions, caption];
        },
        []
      );

      const captionsList = this.context.captionLanguages;

      return captionsList.filter(caption => {
        return availableLanguages.includes(caption.id);
      });
    }

    return null;
  };

  handleSelectLanguage = languageId => {
    this.setState({ languageId: languageId });
  };

  handleCaptionUploadChange = e => {
    let files = e.target.files || e.dataTransfer.files;

    if (files && files[0]) {
      const extension = files[0].name.split(".").pop();

      if (extension === "srt") {
        this.setState({ captionFile: files[0], invalidFileType: false });
      } else {
        this.setState({ invalidFileType: true });
      }
    }
  };

  // Close the popup and reset all state.
  handleModalClose = () => {
    this.props.closeCaptionsModal();

    this.setState({
      isLoading: true,
      selectedTranscription: "order",
      selectedMenu: "order",
      orderLanguages: [1],
      additionalNotes: null,
      languageId: "en-us",
      captionFile: null,
      captionContent: null,
      canDisplayPaymentModal: false,
      canDisplayCaptionEditor: false,
      processingEdit: false,
      editComplete: false,
      editFailed: false,
      processingUpload: false,
      uploadComplete: false,
      uploadFailed: false,
      invalidFileType: false,
      modalTitle: "Order Closed Captioning"
    });
  };

  getCaptionContent = () => {
    const videoCaptions = this.state.videoData.video_captions;
    const captionData = Object.values(videoCaptions).filter(caption => {
      return caption.caption_language_id == this.state.languageId;
    });

    return captionData.pop();
  };

  selectMenu = type => {
    let title = "Order Closed Captioning";

    if (type == "upload") {
      title = "Upload New Captions";
    } else if (type == "edit") {
      title = "Download/Edit Captions";
    }

    this.setState({
      selectedMenu: type,
      languageId: "1",
      modalTitle: title
    });
  };

  showPaymentModal = () => {
    this.setState({ canDisplayPaymentModal: true });
  };

  hidePaymentModal = () => {
    this.setState({ canDisplayPaymentModal: false });
  };

  async selectLanguages(selected) {
    const selectedLanguages = [...this.state.orderLanguages];
    const index = selectedLanguages.indexOf(selected);

    if (selected !== 1) {
      if (selectedLanguages.includes(selected)) {
        selectedLanguages.splice(index, 1);
      } else {
        selectedLanguages.push(selected);
      }

      await this.setState({ orderLanguages: selectedLanguages });
    }
  }

  getSelectedLanguages(language) {
    const languages = this.state.orderLanguages;

    return languages.includes(language);
  }

  getLanguagePrice = language => {
    if (language == undefined) {
      return 0;
    }

    let duration = this.context.videoDuration;

    // If video is less than a minute, we are charging full minute.
    if (duration < 60) {
      return language.cents_per_minute / 100;
    }

    let minutes = Math.floor(duration / 60);
    let seconds = duration % 60;

    return (
      (language.cents_per_minute * minutes +
        language.cents_per_second * seconds) /
      100
    );
  };

  calculateOrderPrice = captionLanguages => {
    const selectedLanguages = this.state.orderLanguages;
    let totalPrice = 0;

    selectedLanguages.forEach(language => {
      const languageInfo = captionLanguages.filter(captionLanguage => {
        return captionLanguage.id === language;
      });

      totalPrice += this.getLanguagePrice(languageInfo[0]);
    });

    return totalPrice.toFixed(2);
  };

  getSubTotal = captionLanguages => {
    const selectedLanguages = this.state.orderLanguages;
    let totalPrice = 0;
    let englishPrice = 0;

    selectedLanguages.forEach(language => {
      const languageInfo = captionLanguages.filter(captionLanguage => {
        return captionLanguage.id === language;
      });

      totalPrice += this.getLanguagePrice(languageInfo[0]);

      if (language == 1) {
        englishPrice = totalPrice;
      }
    });

    // If they order foreign language, English is free of charge.
    if (selectedLanguages.length > 1) {
      totalPrice -= englishPrice;
    }

    return totalPrice.toFixed(2);
  };

  uploadCaption = e => {
    e.preventDefault();

    const uploadUrl = `${process.env.REACT_APP_API_URL}videos/${this.context.videoId}/captions/upload`;
    const file = this.state.captionFile;
    const blob = new Blob([file], { type: "text/srt" });
    const formData = new FormData();

    if (file == null) {
      this.setState({
        processingUpload: false,
        uploadComplete: false,
        uploadFailed: true
      });
      return false;
    }

    this.setState({ processingUpload: true });

    formData.append("token", this.context.token);
    formData.append("caption_language_id", this.state.languageId);
    formData.append("caption_file", blob, file.name);

    axios
      .post(uploadUrl, formData)
      .then(response => {
        this.setState({ processingUpload: false, uploadComplete: true });
      })
      .catch(() => {
        this.setState({
          processingUpload: false,
          uploadComplete: false,
          uploadFailed: true
        });
      });
  };

  editCaption = (e, captionContentValue) => {
    e.preventDefault();

    const editUrl = `${process.env.REACT_APP_API_URL}videos/${this.context.videoId}/captions/edit`;
    const videoCaptions = this.state.videoData.video_captions;
    const captionData = Object.values(videoCaptions)
      .reverse()
      .filter(caption => {
        return caption.caption_language_id == this.state.languageId;
      });

    const params = {
      video_caption_id: captionData[0].id,
      srt_content: captionContentValue,
      token: this.context.token
    };

    this.setState({ processingEdit: true });

    axios
      .post(editUrl, params)
      .then(response => {
        this.setState({ processingEdit: false, editComplete: true });
      })
      .catch(() => {
        this.setState({
          processingEdit: false,
          editComplete: false,
          editFailed: true
        });
      });
  };

  /**
   * Render loader.
   */
  renderLoader() {
    return (
      <div className="text-center" style={{ height: "50px" }}>
        <Loader />
      </div>
    );
  }

  renderCaptionModalContent(captionLanguages) {
    switch (this.state.selectedMenu) {
      case "upload":
        return (
          <UploadCaption
            captionLanguages={captionLanguages}
            invalidFileType={this.state.invalidFileType}
            processingUpload={this.state.processingUpload}
            uploadComplete={this.state.uploadComplete}
            uploadFailed={this.state.uploadFailed}
            languageId={this.state.languageId}
            selectedMenu={this.state.selectedMenu}
            selectMenu={this.selectMenu}
            handleSelectLanguage={this.handleSelectLanguage}
            handleCaptionUploadChange={this.handleCaptionUploadChange}
            uploadCaption={this.uploadCaption}
          />
        );
      case "edit":
        return (
          <EditCaption
            captionLanguages={this.availableLanguagesForEdit()}
            processingEdit={this.state.processingEdit}
            editComplete={this.state.editComplete}
            editFailed={this.state.editFailed}
            languageId={this.state.languageId}
            selectedMenu={this.state.selectedMenu}
            selectMenu={this.selectMenu}
            handleSelectLanguage={this.handleSelectLanguage}
            getCaptionContent={this.getCaptionContent}
            editCaption={this.editCaption}
          />
        );
      default:
        if (!this.state.canDisplayPaymentModal) {
          return (
            <OrderCaption
              captionLanguages={captionLanguages}
              videoDuration={this.context.videoDuration}
              orderLanguages={this.state.orderLanguages}
              selectedMenu={this.state.selectedMenu}
              selectMenu={this.selectMenu}
              getSelectedLanguages={this.getSelectedLanguages}
              getLanguagePrice={this.getLanguagePrice}
              selectLanguages={this.selectLanguages}
              showPaymentModal={this.showPaymentModal}
              subTotal={this.getSubTotal(captionLanguages)}
            />
          );
        } else {
          const stripeKey = process.env.REACT_APP_STRIPE_KEY;
          const orderDetails = {
            caption_languages: this.state.orderLanguages,
            amount: this.calculateOrderPrice(captionLanguages)
          };

          return (
            <StripeProvider apiKey={stripeKey}>
              <Elements>
                <CheckoutForm
                  orderDetails={orderDetails}
                  videoData={this.state.videoData}
                  videoId={this.context.videoId}
                  token={this.context.token}
                  hidePaymentModal={this.hidePaymentModal}
                  subTotal={this.getSubTotal(captionLanguages)}
                />
              </Elements>
            </StripeProvider>
          );
        }
    }
  }

  render() {
    const captionLanguages = this.context.captionLanguages;
    const showCaptionsModal = this.props.showCaptionsModal;

    return (
      <Modal
        show={showCaptionsModal}
        onHide={() => this.handleModalClose()}
        dialogClassName="manage-captions-modal"
        keyboard={false}
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.state.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {captionLanguages.length > 0 ? (
            <Form>{this.renderCaptionModalContent(captionLanguages)}</Form>
          ) : (
            this.renderLoader()
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

ManageCaptionsModal.contextType = CaptionContext;

export default ManageCaptionsModal;
