import React from 'react';
import {Row, Col, Modal, Accordion } from "react-bootstrap";
import RevisionRow from './RevisionRow';
import RevisionVideoPlayer from './RevisionVideoPlayer';
import Loader from './Loader';

class RevisionHistoryModal extends React.Component {
    render() {
        const {showModal, closeModal, selectedRevision, setSelectedRevision, revisions, branding} = this.props;

        return <Modal show={ showModal } onHide={ closeModal } dialogClassName="revision-history-modal">
            <Modal.Header closeButton>
                <Modal.Title>Revision History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    revisions.length > 0 ?
                        <Row>
                            <Col md={6}>
                                <Accordion defaultActiveKey="0" className="panel panel-primary">
                                    {revisions.map(
                                        (revision, index) => <RevisionRow revision={revision} key={index} index={index} setSelectedRevision={setSelectedRevision}/>
                                    )}
                                </Accordion>
                            </Col>
                            <Col md={6}>
                                {
                                    selectedRevision.mp4_video_revision_version_url &&
                                    /* IMPORTANT!!! We have to assign a KEY to this component, so when a new revision is selected, this component will be mounted again, and videojs will be reloaded. */
                                    <RevisionVideoPlayer
                                        mp4VideoRevisionVersionUrl={selectedRevision.mp4_video_revision_version_url}
                                        branding={branding}
                                        key={selectedRevision.id}
                                    />
                                }
                            </Col>
                        </Row>
                        :
                        <div className="text-center" style={{height: "50px"}}><Loader /></div>
                }
            </Modal.Body>
        </Modal>
    }
}

export default RevisionHistoryModal;
