import React from 'react';
import ShareThis from "./ShareThis";
import {Col, Row} from "react-bootstrap";
import EmbedCode from './EmbedCode';
import QrCode from './QrCode';
import DownloadLinks from './DownloadLinks';
import { isEmpty } from "lodash";

class BasicDownloadAndShare extends React.Component {
    render() {
        const {
            canDownloadVideo,
            videoUrlWithoutToken,
            title,
            cdnMedia,
            branding
        } = this.props;

        let shareThisSection;

        if (isEmpty(branding)) {
            shareThisSection = <ShareThis 
                videoUrlWithoutToken={videoUrlWithoutToken} 
                title={title}
            />
        }

        return <div>
            <div id="sharing-and-actions-section" className="text-center large-margin-bottom">
                <Row>
                    <Col lg={12} className="text-left">
                        { canDownloadVideo ?
                            <p className="p-lg">Download and share your video via one of the options below.</p>
                            : <p className="p-lg">Share your video via one of the options below.</p>
                        }
                    </Col>
                </Row>
                {shareThisSection}
            </div>
            <div id="video-actions">
                <Row>
                    <Col>
                        {  canDownloadVideo && cdnMedia && <DownloadLinks cdnMedia={cdnMedia}/> }
                        <EmbedCode videoUrlWithoutToken={videoUrlWithoutToken}/>
                        <div className="clearfix"/>
                        <QrCode videoUrlWithoutToken={videoUrlWithoutToken}/>
                    </Col>
                </Row>
            </div>
        </div>
    }

}

export default BasicDownloadAndShare;