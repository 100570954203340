import React from 'react';
import bootbox from "bootbox";

class EmbedCode extends React.Component {
    constructor(props) {
        super(props);

        this.handleEmbedCodeClick = this.handleEmbedCodeClick.bind(this);
        this.sanitize = this.sanitize.bind(this);
    }

    sanitize(str) {
        return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    }

    handleEmbedCodeClick() {
        const embedUrl = `${this.props.videoUrlWithoutToken}/embed`;

        const embedCode = '<iframe width="768" height="432" src="'+ embedUrl +'" frameBorder="0" allowFullScreen ' +
            'webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>';

        bootbox.alert({
            title: 'Embed Code',
            message: this.sanitize(embedCode),
            size: 'lg'
        });
    }

    render() {
        return <div className="bar-button hover-fade small-margin-bottom" title="Embed Code" onClick={ this.handleEmbedCodeClick }>
            <i className="bar-button__icon fas fa-code"></i><span className="bar-button__text">Embed Code</span>
        </div>
    }
}

export default EmbedCode;