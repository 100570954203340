import React from 'react';
import {Col, Row, Overlay, Tooltip} from 'react-bootstrap';
import Clipboard from 'react-clipboard.js';

class ShareLink extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showTooltip: false
        };

        this.copyIcon = React.createRef();
        this.videoLinkCopied = this.videoLinkCopied.bind(this);
    }

    videoLinkCopied() {
        this.setState({
            showTooltip: true
        });

        setTimeout(() => {
            this.setState({
                showTooltip: false
            })
        }, 4000);
    }

    render() {
        const { videoLink } = this.props;

        return <Row>
            <Col lg={12}>
                <p>Just want to share a link to the video?<br/>Please use the one below.</p>
            </Col>
            <Col>
                <a id="video_link" href={videoLink} target="_blank" rel="noopener noreferrer">{videoLink}</a>&nbsp;&nbsp;
                <Clipboard component="i" data-clipboard-text={videoLink} title="Copy the link" className="far fa-copy" style={{cursor: "pointer"}} onSuccess={this.videoLinkCopied} ref={this.copyIcon}/>
                <Overlay target={this.copyIcon.current} show={this.state.showTooltip} placement="right">
                    {props => (
                        <Tooltip id="video-link-copied" {...props}>
                            Copied
                        </Tooltip>
                    )}
                </Overlay>
            </Col>
        </Row>
    }

}

export default ShareLink;
