import React from 'react';
import {Form, ButtonToolbar, Button} from 'react-bootstrap';
import bootbox from 'bootbox';
import axios from "../_config/axios";

class FacebookUploadForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitText: 'Upload to Facebook',
            title: "",
            description: "",
            pageId: "",
            disableUploading: false
        };

        this.confirmUploadingToFacebook = this.confirmUploadingToFacebook.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.uploadToFacebook = this.uploadToFacebook.bind(this);
    }

    componentDidMount() {
        this.setState({
            title: this.props.video.title
        });
    }

    confirmUploadingToFacebook() {
        const {video, token} = this.props;
        const {title, description, pageId} = this.state;
        const videoId = video.id;

        this.setState({
            submitText: 'Uploading...',
            disableUploading: true
        });

        bootbox.confirm(`A video can only be uploaded once to Facebook. 
            You can re-share the video as a Facebook page admin.
            Once clicking upload, <strong style="color: red;"> PLEASE DO NOT REFRESH</strong> and <strong style="color: red;">STAY ON THIS FORM</strong> until the upload process is completed. 
            Are you sure you want to upload this video to Facebook?`, (result) => {
            if (!result) {
                this.setState({
                    submitText: 'Upload to Facebook',
                    disableUploading: false
                });
                return;
            }

            this.uploadToFacebook({
                token,
                video_id: videoId,
                facebook_title: title,
                facebook_description: description,
                facebook_page_id: pageId
            });
        });
    }

    uploadToFacebook(input) {
        const {updateVideoActions} = this.props;

        return axios.post(`${process.env.REACT_APP_API_URL}facebook/videos`, {...input})
            .then(({data}) => {
                this.setState({submitText: 'Uploaded'});
                bootbox.alert(`
                        <h4>Congratulations! Your video has been successfully uploaded to Facebook!</h4>
                        <p>It often takes 1-2 minutes for the video to be available on Facebook, but when it's completed, it will be available on this link:</p>
                        <p class="large-margin-bottom"><a href="${data.url}" target="_blank">${data.url}</a></p>`,
                    () => updateVideoActions()
                );
            })
            .catch((error) => {
                this.setState({
                    submitText: 'Upload to Facebook',
                    disableUploading: false
                });

                // This part will have to be updated ONCE the request change PR is merged.
                if (error.response && error.response.status === 422) {
                    const errorMessages = Object.values(error.response.data).flat().map(errorMessage => `<li>${errorMessage}</li>`).join('');
                    bootbox.alert(`<ul style="color: red">${errorMessages}</ul>`);
                }

                if (error.response && error.response.status === 400) {
                    bootbox.alert(error.response.data.message);
                }
            });
    }

    handleInputChange(e) {
        const {name, value} = e.target;

        this.setState({[name]: value});
    }

    render() {
        return <div>
            <h4>Let's get sharing!</h4>
            <p>Facebook Upload. The upload process may take a while. <br/><strong style={{color: 'red'}}>PLEASE DO NOT REFRESH</strong> and <strong style={{color: 'red'}}>STAY ON THIS FORM</strong> until the upload process is completed.</p>
            <Form>
                <Form.Group controlId="title">
                    <Form.Control text="text" placeholder="Video title" name="title" value={this.state.title} onChange={this.handleInputChange}/>
                </Form.Group>
                <Form.Group controlId="description">
                    <Form.Control as="textarea" placeholder="Video description" name="description" value={this.state.description} onChange={this.handleInputChange}/>
                </Form.Group>
                <Form.Group controlId="facebook_page">
                    <Form.Control as="select" name="pageId" value={this.state.pageId} onChange={this.handleInputChange}>
                        <option value="">-- Please select a page --</option>
                        {
                            this.props.facebookPages.map(
                                (facebookPage, index) => <option value={facebookPage.id} key={index + 1}>{facebookPage.name}</option>
                            )
                        }
                    </Form.Control>
                </Form.Group>
                <ButtonToolbar>
                    <Button variant="success" className="small-margin-right small-margin-bottom" onClick={this.confirmUploadingToFacebook} disabled={this.state.disableUploading}>{this.state.submitText}</Button>
                    <Button variant="danger" className="small-margin-right small-margin-bottom" onClick={() => this.props.setActiveComponent('downloadAndShare')} disabled={this.state.disableUploading}>Back to share</Button>
                </ButtonToolbar>
            </Form>
        </div>
    }
}

export default FacebookUploadForm;