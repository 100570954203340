import React from 'react';

class YoutubeUrlAndNumberOfViews extends React.Component {
    render() {
        const {youtubeUrl, youtubeStats} = this.props;

        return <div className="small-margin-bottom">
            <p>{youtubeStats} views on youtube<br/>
                <a href={youtubeUrl} target="_blank" rel="noopener noreferrer">{youtubeUrl}</a>
            </p>
        </div>
    }

}

export default YoutubeUrlAndNumberOfViews;