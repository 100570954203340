import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../app.scss";
import Video from "./Video";
import VideoEmbed from "./VideoEmbed";
import NotFound from "./NotFound";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={NotFound} />
        <Route exact path="/:id" component={Video} />
        <Route exact path="/:id/embed" component={VideoEmbed} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
