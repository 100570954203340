import React from 'react';
import Loader from "./Loader";

class VideoChangesUploaded extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    /**
     * Render loader.
     */
    renderLoader() {
        return (
            <div className="wide-container">
                <div className="text-center extra-large-margin-top">
                    <Loader />
                </div>
            </div>
        );
    }

    /**
     * Render video.
     */
    renderChangesUploaded() {
        return (
            <p>Your changes have been received and are being actioned by the Visual Domain team.</p>
        );
    }

    render() {
        return (this.state.isLoading) ? this.renderLoader() : this.renderChangesUploaded();
    }
}

export default VideoChangesUploaded;