import React from 'react';
import {Button} from 'react-bootstrap';
import YoutubeUploadForm from "./YoutubeUploadForm";

class YoutubeAuthorisation extends React.Component {
    render() {
        return <div>
            <div className="medium-margin-bottom">
                <p>Visual Domain hasn't yet been authorised to upload videos to Youtube on your behalf. Please follow the prompts below if you would like us to do so.</p>
                <p><span style={{color: "red"}}>NOTE:</span> Before starting this process, please check that you have a Youtube channel set up. If you need to create a channel you can do so via the following link: <br/>
                    <a className="underline-link-style" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/create_channel">https://www.youtube.com/create_channel</a>:
                </p>
            </div>
            <p><Button className="medium-margin-bottom" variant="success" href={this.props.youtubeAuthorisationUrl}>Continue to Authorisation</Button></p>
            {
                ! this.props.youtubeUrl && <YoutubeUploadForm
                    setActiveComponent={this.props.setActiveComponent}
                    updateVideoActions={this.props.updateVideoActions}
                    token={this.props.token}
                    youtubeChannel={this.props.youtubeChannel}
                    video={this.props.video}
                />
            }
        </div>
    }
}

export default YoutubeAuthorisation;