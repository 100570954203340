import React from 'react';

function Loader() {
    return (
        <div>
            <span className="loader inline-block-style"></span>
        </div>
    );
}

export default Loader;