import React from 'react';
import Loader from "./Loader";
import YoutubeUploadForm from './YoutubeUploadForm';
import BasicDownloadAndShare from "./BasicDownloadAndShare";
import YoutubeUrlAndNumberOfViews from "./YoutubeUrlAndNumberOfViews";
import UploadInternalVideoToYoutubeButton from "./UploadInternalVideoToYoutubeButton";

class InternalVideoDefault extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            activeComponent: 'downloadAndShare',
            videoUrlWithoutToken: '',
            title: '',
            description: '',
            youtubeUrl: ''
        };

        this.goToYoutubeForm = this.goToYoutubeForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setActiveComponent = this.setActiveComponent.bind(this);
    }

    componentDidMount() {
        const videoUrlWithoutToken = window.location.origin + '/' + this.props.video.id;
        this.setState({ isLoading: false, videoUrlWithoutToken, title: this.props.video.title });
    }

    handleInputChange(event) {
        this.setState({ [event.target.name]: event.target.value});
    }

    goToYoutubeForm() {
        this.setState({ activeComponent: 'youtubeUploadForm'});
    }

    setActiveComponent(component) {
        this.setState({activeComponent: component});
    }

    /**
     * Render loader.
     */
    renderLoader() {
        return (
            <div className="wide-container">
                <div className="text-center extra-large-margin-top">
                    <Loader />
                </div>
            </div>
        );
    }

    /**
     * Render video.
     */
    renderActions() {
        if (this.props.token) {
            if (this.state.activeComponent === 'youtubeUploadForm' && this.props.video.youtube_channel) {
                return <YoutubeUploadForm
                    setActiveComponent={this.setActiveComponent}
                    videoId={this.props.video.id}
                    updateVideoActions={this.props.updateVideoActions}
                    token={this.props.token}
                    videoTitle={this.props.video.title}
                    youtubeChannel={this.props.video.youtube_channel}
                    videoType={this.props.video.video_type}
                    video={this.props.video}
                />;
            }

            return <>
                <BasicDownloadAndShare
                    canDownloadVideo={false}
                    videoUrlWithoutToken={this.state.videoUrlWithoutToken}
                    title={this.props.video.title}
                    cdnMedia={{}}
                />
                {
                    this.props.token && this.props.video.is_valid_token && !this.props.video.youtube_url &&
                        <UploadInternalVideoToYoutubeButton videoUrlWithoutToken={this.state.videoUrlWithoutToken} goToYoutubeForm={this.goToYoutubeForm}/>
                }
                {
                    this.props.video.youtube_url && <YoutubeUrlAndNumberOfViews youtubeUrl={this.props.video.youtube_url} youtubeStats={this.props.video.youtube_stats}/>
                }
            </>
        }

        return null;
    }

    render() {
        return (this.state.isLoading) ? this.renderLoader() : this.renderActions();
    }
}

export default InternalVideoDefault;