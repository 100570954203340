import React from 'react';
import {Button} from 'react-bootstrap';

class FacebookAuthorisation extends React.Component {
    render() {
        const { facebookAuthorisationUrl, setActiveComponent } = this.props;

        return <div>
            <div className="medium-margin-bottom">
                <p>Visual Domain hasn't yet been authorised to upload videos to Facebook on your behalf. Please follow the prompts below if you would like us to do so.</p>
                <p><span style={{color: 'red'}}>NOTE:</span> Before starting this process, please check that you have a Facebook page set-up. If you need to create a Facebook page, please create one via your user account.</p>
            </div>
            <p>
                <Button variant="success" className="small-margin-right small-margin-bottom" href={facebookAuthorisationUrl}>Continue to Facebook</Button>
                <Button variant="danger" className="small-margin-right small-margin-bottom" onClick={() => setActiveComponent('downloadAndShare')}>Go back</Button>
            </p>
        </div>
    }
}

export default FacebookAuthorisation;