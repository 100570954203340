import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import ShareLink from "./ShareLink";

class UploadInternalVideoToYoutubeButton extends React.Component {
    render() {
        const {goToYoutubeForm, videoUrlWithoutToken} = this.props;

        return <>
            <Row className="medium-margin-bottom youtube-actions">
                <Col lg={12}>
                    <div className="inline-block-style no-underline" onClick={ goToYoutubeForm }>
                        <Button variant="danger">
                            <i className="fab fa-youtube small-margin-right" aria-hidden="true"></i>Post to visdom Youtube channel
                        </Button>
                    </div>
                </Col>
            </Row>
            { <ShareLink videoLink={ videoUrlWithoutToken }/> }
        </>

    }
}

export default UploadInternalVideoToYoutubeButton;