import React from "react";
import { Modal } from "react-bootstrap";

class CaptionVideoModal extends React.Component {
  constructor(props) {
    super(props);
  }

  handleModalClose = () => {
    this.props.closeVideoModal();
  };

  render() {
    const { showVideoModal } = this.props;

    return (
      <Modal
        show={showVideoModal}
        onHide={() => this.handleModalClose()}
        dialogClassName="manage-captions-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <iframe
            title="caption teaser"
            className="caption-teaser-iframe"
            src="https://video.visualdomain.com.au/63650/embed"
            align="middle"
            frameBorder="0"
            allowFullScreen={true}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            oallowfullscreen="true"
            msallowfullscreen="true"
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default CaptionVideoModal;
