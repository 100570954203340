import React from "react";
import AnnotationForm from "./AnnotationForm";
import Annotations from "./Annotations";
import { RequestChangesInstructions } from "./General";
import RequestChangesBasicForm from "./RequestChangesBasicForm";

class RequestChangesAdvancedForm extends React.Component {
  render() {
    const {
      orderJobChange,
      sendAnnotation,
      removeAnnotation,
      updateAnnotation,
      updateOrderJobChange,
      updateVideoActions,
      upload,
      removeAttachment,
      submitChanges,
      validateDescription
    } = this.props;

    const willBeFirstAnnotation =
      !orderJobChange.order_job_change_annotations ||
      (orderJobChange.order_job_change_annotations &&
        orderJobChange.order_job_change_annotations.length === 0);

    return (
      <>
        {RequestChangesInstructions()}
        <AnnotationForm
          sendAnnotation={sendAnnotation}
          willBeFirstAnnotation={willBeFirstAnnotation}
        />
        <Annotations
          annotations={orderJobChange.order_job_change_annotations}
          removeAnnotation={removeAnnotation}
          updateAnnotation={updateAnnotation}
        />
        <RequestChangesBasicForm
          updateOrderJobChange={updateOrderJobChange}
          updateVideoActions={updateVideoActions}
          orderJobChange={orderJobChange}
          upload={upload}
          removeAttachment={removeAttachment}
          submitChanges={submitChanges}
          validateDescription={validateDescription}
        />
      </>
    );
  }
}

export default RequestChangesAdvancedForm;
