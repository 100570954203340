import React from "react";
import VisualDomainLogo from "../_assets/images/visual-domain-logo-on-dark.png";
import { isEmpty } from "lodash";

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  renderDefault() {
    return (
      <header>
        <div className="brand-logo"> 
          <a href="https://www.visualdomain.com.au" target="_blank" rel="noopener noreferrer">
              <img className="logo" src={VisualDomainLogo} alt="Visual Domain" />
          </a>
        </div>
      </header>
    );
  }

  renderCustomBranding() {
    return (
      <header>
        <div className="brand-logo" 
          style={{ 
            background: this.props.branding.main_color,
            borderColor: this.props.branding.sub_color 
          }}
        >             
          <a href={this.props.branding.website_url} target="_blank" rel="noopener noreferrer">
              <img className="logo" 
                src={ !isEmpty(this.props.branding.logo_url) ? this.props.branding.logo_url : VisualDomainLogo} 
                alt="Logo" 
              />
          </a>
        </div>
      </header>
    );
  }

  render() {
    return (isEmpty(this.props.branding) ? this.renderDefault() : this.renderCustomBranding())
  }
}

export default Header;
