import React from "react";
import { Row } from "react-bootstrap";

export function ClientIsReady() {
  return (
    <div>
      <p className="p-lg">We got it!</p>
      <p className="p-lg">
        Thanks for submitting your video changes. You’ll shortly receive an
        email summary of the changes you requested and our team will start
        reviewing them straight away.
      </p>
      <p className="p-lg">
        We’ll contact you if we have any questions or we’ll send through an
        updated link just as soon as they are complete.
      </p>
      <p className="p-lg">
        You can review your video at any time via your video link or by
        following the “See Revision History” button below.
      </p>
    </div>
  );
}

export function RequestChangesInstructions() {
  return (
    <Row className="small-margin-top">
      <ol id="change-annotation-instruction" className="medium-margin-bottom">
        <li>
          Pause your video at the start of the video section requiring a change
          and press 'Record Start Time'.
        </li>
        <li>
          Pause the video at the end of the section requiring a change, and
          press 'Record End Time'.
        </li>
        <li>
          Enter a clear description of the changes required in the text box
          below and save.
        </li>
      </ol>
    </Row>
  );
}
