import React from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Form,
  Card
} from "react-bootstrap";

class CaptionMenu extends React.Component {
  render() {
    const { selectMenu, selectedMenu } = this.props;
    return (
      <Form.Row>
        <Form.Group as={Col} controlId="formGridCaptionType">
          <Card.Title className="caption--headings">
            Select one of the below
          </Card.Title>
          <ButtonToolbar aria-label="Toolbar with button groups">
            <ButtonGroup
              className="col-sm-4 caption--options-transcription--container"
              aria-label="Order caption"
            >
              <Button
                className={`caption--options-transcription--btn ${
                  selectedMenu === "order" ? "selected" : ""
                }`}
                onClick={() => selectMenu("order")}
              >
                Order Captions
              </Button>
            </ButtonGroup>
            <ButtonGroup
              className="col-sm-4 caption--options-transcription--container"
              aria-label="Upload caption"
            >
              <Button
                className={`caption--options-transcription--btn ${
                  selectedMenu === "upload" ? "selected" : ""
                }`}
                onClick={() => selectMenu("upload")}
              >
                Upload Captions
              </Button>
            </ButtonGroup>
            <ButtonGroup
              className="col-sm-4 caption--options-transcription--container"
              aria-label="Edit caption"
            >
              <Button
                className={`caption--options-transcription--btn ${
                  selectedMenu === "edit" ? "selected" : ""
                }`}
                onClick={() => selectMenu("edit")}
              >
                Download/Edit Captions
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Form.Group>
      </Form.Row>
    );
  }
}

export default CaptionMenu;
