import React from "react";
import { Button, Form, Card, Spinner } from "react-bootstrap";
import CaptionMenu from "./CaptionMenu";

class UploadCaption extends React.Component {
  render() {
    if (!this.props.uploadComplete && !this.props.uploadFailed) {
      return (
        <>
          <div className="caption--body medium-margin-bottom">
            <Form.Group controlId="formGridCaptionLanguages">
              <CaptionMenu
                selectedMenu={this.props.selectedMenu}
                selectMenu={this.props.selectMenu}
              />
              <Form.Label className="caption--headings">
                Select a language
              </Form.Label>
              <Form.Control
                as="select"
                defaultValue={this.props.languageId}
                ref="uploadCaptionSelect"
                onChange={() =>
                  this.props.handleSelectLanguage(
                    this.refs["uploadCaptionSelect"].value
                  )
                }
              >
                {this.props.captionLanguages.map((language, index) => (
                  <option key={language.id} value={language.id}>
                    {language.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formGridCaptionLanguages">
              <Form.Label className="caption--headings">
                Upload your .SRT file
              </Form.Label>
              <div className="upload-input-wrapper">
                <input
                  type="file"
                  accept=".srt"
                  ref="uploadCaptionFile"
                  onChange={e => this.props.handleCaptionUploadChange(e)}
                />
                {this.props.invalidFileType ? (
                  <div className="error-text small-margin-top">
                    Invalid file type. Please try again.
                  </div>
                ) : null}
              </div>
            </Form.Group>
          </div>
          <div className="caption--footer">
            <div className="caption--footer--info"></div>
            <div className="caption--footer--submit">
              <Button
                className="caption--footer--submit-btn"
                disabled={this.props.processingUpload}
                onClick={e => {
                  this.props.uploadCaption(e);
                }}
              >
                {!this.props.processingUpload ? (
                  "Upload"
                ) : (
                  <>
                    <span className="small-margin-right">Processing </span>
                    <Spinner as="span" animation="border" size="sm" />
                  </>
                )}
              </Button>
            </div>
            <div className="clearfix"></div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="caption--body medium-margin-bottom">
            <Form.Label className="caption--headings medium-margin-bottom">
              {this.props.uploadComplete
                ? "Caption Successfully Added!"
                : "Failed to Upload Caption"}
            </Form.Label>
            <Card.Subtitle className="caption--sub-heading">
              {this.props.uploadComplete
                ? "We have successfully added your caption. Please refresh this page to see your caption."
                : "There seems to be an error when we try to upload the file, please close this popup and try again."}
            </Card.Subtitle>
          </div>
        </>
      );
    }
  }
}

export default UploadCaption;
