import React from 'react';
import {Alert, Button, ButtonToolbar, Form, Spinner} from 'react-bootstrap';
import bootbox from 'bootbox';
import {timeCode, validationErrorMessages} from "../helpers";
import {ActionsContext} from "../_context/ActionsContext";
import $ from 'jquery';

class Annotation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            deleteOrUpdateInProgress: false,
            commentText: ""
        };

        this.setEditMode = this.setEditMode.bind(this);
        this.setVideoTime = this.setVideoTime.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.confirmRemoveAnnotation = this.confirmRemoveAnnotation.bind(this);
    }

    componentDidMount() {
        this.setState({
            commentText: this.props.annotation.comment
        })
    }

    setEditMode(value) {
        this.setState({
            commentText: this.props.annotation.comment,
            editMode: value
        })
    }

    setVideoTime(time) {
        const {html_video: primaryPlayer} = window.videojs.players;

        primaryPlayer.pause();
        primaryPlayer.currentTime(time);

        $("html, body").animate({ scrollTop: 0 }, 600);
    }

    handleUpdate() {
        if (! this.state.commentText) {
            this.setState({
                commentText: this.props.annotation.comment
            });

            this.setEditMode(false);
            return;
        }

        const id = this.props.annotation.id;
        const comment = this.state.commentText;

        this.setState({deleteOrUpdateInProgress: true});

        this.props.updateAnnotation({id, comment})
            .then(({data: updatedAnnotation}) => {
                bootbox.alert('The annotation has been updated successfully.');
                this.context.reviseAnnotations(updatedAnnotation);
            })
            .catch(({response}) => {
                if (response.status === 422) {
                    // 1. if response got a status of 422, its validation error.
                    bootbox.alert(
                        validationErrorMessages(response.data)
                    );
                }
            })
            .finally(() => this.setState({deleteOrUpdateInProgress: false}));

        this.setEditMode(false);
    }

    handleCommentChange(e) {
        this.setState({
            commentText: e.target.value
        })
    }

    handleCancel() {
        this.setState({
            commentText: this.props.annotation.comment
        });

        this.setEditMode(false);
    }

    confirmRemoveAnnotation(annotationId) {
        bootbox.confirm('Are you sure you want to delete this annotation?', (result) => {
            if (!result) return;

            this.setState({deleteOrUpdateInProgress: true});

            this.props.removeAnnotation(annotationId)
                .then(({data: removedAnnotation}) => {
                    bootbox.alert("The annotation has been removed.");
                    this.context.detachAnnotation(removedAnnotation);
                })
                .finally(() => this.setState({deleteOrUpdateInProgress: false}));
        })
    }

    render() {
        const {from, to, author_name: authorName, comment, id} = this.props.annotation;
        const {editMode} = this.state;

        return <Alert
            className="annotation"
            variant="secondary"
            onClose={() => this.confirmRemoveAnnotation(id)}
            dismissible>
            <p className="hover-pointer" onClick={() => this.setVideoTime(from)}>{`${timeCode(from)} - ${timeCode(to)}`}&nbsp;&nbsp;{this.state.deleteOrUpdateInProgress && <Spinner animation="border" size="sm" as="span"/>}</p>
            <p className="font-italic hover-pointer" onClick={() => this.setVideoTime(from)}>Added by {authorName}</p>
            {
                !editMode ?
                    <div className="full-width">
                        {comment}
                        <i onClick={() => this.setEditMode(true)} className="fa fa-pencil float-right"/>
                    </div>
                    :
                    <div className="full-width">
                        <Form.Group controlId="annotation" className="small-margin-top">
                            <Form.Control
                                as="textarea"
                                rows={5} cols={50}
                                name="commentText"
                                value={this.state.commentText}
                                onChange={this.handleCommentChange}
                            />
                        </Form.Group>
                        <ButtonToolbar className="large-margin-bottom justify-content-end">
                            <Button variant="success" className="small-margin-right sav" onClick={this.handleUpdate}><i className="fa fa-check"/></Button>
                            <Button variant="light" onClick={this.handleCancel}><i className="fa fa-times"/></Button>
                        </ButtonToolbar>
                    </div>
            }
        </Alert>
    }
}

Annotation.contextType = ActionsContext;

export default Annotation;